const types = {
  current: 'current',
  extra: 'extra',
  replay: 'replay',
};

export default {
  stop: {
    icon: 'parking',
    iconSize: 0.6,
    text: true,
    type: types.extra,
    name: 'stop',
  },
  startPoint: {
    icon: 'emptymoon',
    iconSize: 0.8,
    text: true,
    type: types.extra,
    name: 'startPoint',
  },
  finishPoint: {
    icon: 'fullmoon',
    iconSize: 0.8,
    text: true,
    type: types.extra,
    name: 'finishPoint',
  },
  arrow: {
    iconSize: 0.45,
    text: true,
    type: types.replay,
    name: 'arrow',
  },
  current: {
    iconSize: 1,
    text: true,
    type: types.current,
    name: 'current',
  },
  currentStop: {
    iconSize: 0.8,
    text: true,
    type: types.current,
    name: 'current',
  },
  radar: {
    iconSize: 1,
    text: true,
    type: types.current,
    name: 'radar',
  },
  bs: {
    icon: 'tower-bs',
    iconSize: 0.5,
    text: true,
    type: types.extra,
    name: 'bs',
  },
  temporary: {
    icon: 'emptymoon',
    iconSize: 1,
    text: true,
    type: types.current,
    name: 'temporary',
  },
  event: {
    icon: 'emptymoon',
    iconSize: 1,
    text: false,
    type: types.extra,
    name: 'replay',
  },
};

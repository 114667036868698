import React, { useState, useCallback, useEffect } from 'react';
import {
  Button, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import PageLayout from '../../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import M3U8 from '../../../common/utils/m3u8';
import VideoCameraBlocksContainer from '../components/VideoCameraBlocksContainer';
import VideoCameraBlock from '../components/VideoCameraBlock';
import VideoCameraActionsWindows from '../components/VideoCameraActionsWindows';
import VideoCameraEventReviewLayer from '../components/VideoCameraEventReviewLayer';
import VideoCameraLayer from '../components/VideoCameraLayer';
import VideoCamerasFiltersPanel from '../components/VideoCamerasFiltersPanel';
import { getCameraNameFromNameDisplayed } from '../utils/videoCameraHelper';
import { periodsEnum } from '../../../common/components/DatetimeCombobox';

const useStyles = makeStyles(() => ({
  noData: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filters: {
    margin: '0 8px',
  },
}));

const breadcrumbs = ['reportTitle', 'reportEventsRecording'];

const VideoCameraEventsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [eventReviewsFiltered, setEventReviewsFiltered] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [datetimeFilter, setDatetimeFilter] = useState({ from: undefined, to: undefined });
  const [videoCamerasIds, setVideoCamerasIds] = useState([]);
  const [selectedVideoCameras, setSelectedVideoCameras] = useState([]);

  const videoCameraLayer = VideoCameraLayer({});
  const eventReviewLayer = VideoCameraEventReviewLayer({ videoCameraLayer });

  const createCameras = useCallback(() => {
    const cameras = [];
    const videoCamerasMetadata = videoCameraLayer.getVideoCamerasMetadata();
    for (const cameraId of videoCamerasIds) {
      const { nameDisplayed } = videoCamerasMetadata[cameraId];
      cameras.push({ name: nameDisplayed, ID: nameDisplayed, selected: selectedVideoCameras.includes(nameDisplayed) });
    }
    return cameras;
  }, [videoCamerasIds, selectedVideoCameras]);

  const treeViewItemSelectionChanged = (items) => {
    setSelectedVideoCameras(items);
  };

  const acceptDeleteConfirmWindow = async (item) => {
    try {
      const { videoRecordService, id } = item;
      const url = `${videoRecordService}/api/reviews/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ids: [id],
        }),
      });

      if (response.ok) {
        setEventReviewsFiltered((prev) => {
          const newVideos = prev.filter((v) => v.id !== id);
          return newVideos;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const videoCameraActionsWindows = VideoCameraActionsWindows({
    onDeleteConfirm: acceptDeleteConfirmWindow,
    type: 'application/x-mpegURL',
    additionalOptions: {
      disablePictureInPicture: true,
      responsive: true,
      fluid: false,
      controlBar: {
        skipButtons: {
          backward: 5,
          forward: 5,
        },
      },
    },
  });

  const errorHandler = (fn) => async () => {
    try {
      setIsLoading(true);

      await fn();

      if (isError) {
        setIsError(false);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAndSetNewEvents = async (after, before, cameraNames = []) => {
    await eventReviewLayer.loadEventReviews({
      afterDateInUnix: after,
      beforeDateInUnix: before,
      camerasArray: cameraNames,
    });

    // Сервер иногда возвращает вне диапазона события
    const events = eventReviewLayer.getEventReviews().filter((e) => e.start_time >= after && e.end_time <= before);
    setEventReviewsFiltered(events);
  };

  const loadPageData = async () => {
    const _ = async () => {
      await videoCameraLayer.loadVideoCameras();

      const videoCameraUniqueIds = [...videoCameraLayer.getVideoCameraUniqueIds().values()];
      const videoCamerasMetadata = videoCameraLayer.getVideoCamerasMetadata();

      const displayedNames = videoCameraUniqueIds.map((cameraId) => videoCamerasMetadata[cameraId].nameDisplayed);
      const cameraNames = videoCameraUniqueIds.map((cameraId) => {
        const { nameDisplayed } = videoCamerasMetadata[cameraId];
        return getCameraNameFromNameDisplayed(nameDisplayed);
      });

      await loadAndSetNewEvents(
        moment().startOf('day').unix(),
        moment().endOf('day').unix(),
        cameraNames,
      );

      setVideoCamerasIds(videoCameraUniqueIds);
      setSelectedVideoCameras(displayedNames);
    };

    const method = errorHandler(_);
    await method();
  };

  const loadEventReviews = async () => {
    const _ = async () => {
      const cameraNames = selectedVideoCameras.map((selectedVideoCamera) => getCameraNameFromNameDisplayed(selectedVideoCamera));
      await loadAndSetNewEvents(
        moment(datetimeFilter.from).unix(),
        moment(datetimeFilter.to).unix(),
        cameraNames,
      );
    };

    const method = errorHandler(_);
    await method();
  };

  useEffect(() => {
    loadPageData();
  }, []);

  const handleApplyFilter = () => {
    loadEventReviews();
  };

  const handleRemoveVideo = (videoItem) => {
    videoCameraActionsWindows.setDataForWindows(videoItem);
    videoCameraActionsWindows.openDeleteConfirmWindow();
  };

  const handlePlayVideo = (videoItem) => {
    videoCameraActionsWindows.setDataForWindows({
      ...videoItem,
      srcUrl: videoItem.videoUrl,
    });
    videoCameraActionsWindows.openVideoPreviewWindow();
  };

  const handleSaveVideo = async (videoItem, startDownload, endDownload) => {
    startDownload();

    try {
      const m3u8 = new M3U8();
      const download = m3u8.start(videoItem.videoUrl, { filename: `${videoItem.text}.mp4` });
      download.on('finished', endDownload).on('error', endDownload).on('aborted', endDownload);
    } catch (error) {
      console.error(error);
    }
  };

  if (isError) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <div style={{ flexDirection: 'column' }} className={classes.noData}>
          <div>{t('globalErrorOccurredReceiving')}</div>
          <Button disabled={isLoading} variant="outlined" color="primary" onClick={loadPageData}>
            {t('globalAgain')}
          </Button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      {videoCameraActionsWindows.ActionsWindows}

      <div className={classes.filters}>
        <VideoCamerasFiltersPanel
          defaultPeriod={periodsEnum.Today}
          datetimeFilter={datetimeFilter}
          setDatetimeFilter={setDatetimeFilter}
          createCameras={createCameras}
          selectedVideoCameras={selectedVideoCameras}
          treeViewItemSelectionChanged={treeViewItemSelectionChanged}
          onApplyFilter={handleApplyFilter}
        />
      </div>

      <VideoCameraBlocksContainer
        isLoading={isLoading}
        isNoData={eventReviewsFiltered.length === 0}
        datesetForPagination={eventReviewsFiltered}
        callbackForPagination={(eventReviewItem) => (
          <VideoCameraBlock
            key={eventReviewItem.id}
            videoCameraItem={{
              ...eventReviewItem,
              id: eventReviewItem.id,
              srcUrl: eventReviewItem.thumbUrl,
              leftText: eventReviewItem.nameDisplayed,
              rightText: eventReviewItem.datetimeText,
            }}
            onPlayVideo={handlePlayVideo}
            onRemoveVideo={handleRemoveVideo}
            onSaveVideo={handleSaveVideo}
          />
        )}
      />
    </PageLayout>
  );
};

export default VideoCameraEventsPage;

import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from '../common/components/LocalizationProvider';

export default function MapContextMenu({ map }) {
  const t = useTranslation();
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const copyButtonRef = useRef();
  let isStoped = false;

  const handleContextMenu = (e) => {
    e.preventDefault();
    if (!isStoped) {
      const lngLat = map.unproject(e.point);
      const coordinates = [lngLat.lat, lngLat.lng];
      setContextMenuPosition({ mouseX: e.point.x, mouseY: e.point.y, coordinates });
    }
  };

  const handleCopyCoordinates = async () => {
    if (contextMenuPosition) {
      const text = `${contextMenuPosition.coordinates[0].toFixed(6)}, ${contextMenuPosition.coordinates[1].toFixed(6)}`;

      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(text);
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;

          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";

          copyButtonRef.current.appendChild(textArea);
          textArea.select();

          try {
            document.execCommand("copy");
          } catch (error) {
            console.error(error);
          } finally {
            textArea.remove();
          }
        }
      } catch (err) {
        console.error('Не удалось скопировать координаты:', err);
      }

      setContextMenuPosition(null);
    }
  };

  const handleStartMenu = () => {
    isStoped = false;
  };

  const handleStopMenu = () => {
    isStoped = true;
  };

  const handleCloseMenu = () => {
    setContextMenuPosition(null);
  };

  useEffect(() => {
    map.on('rotatestart', handleStopMenu);
    map.on('pitchstart', handleStopMenu);

    map.on('rotateend', handleStartMenu);
    map.on('pitchend', handleStartMenu);

    map.on('contextmenu', handleContextMenu);

    return () => {
      map.off('contextmenu', handleContextMenu);
      map.off('rotatestart', handleStopMenu);
      map.off('pitchstart', handleStopMenu);

      map.off('rotateend', handleStartMenu);
      map.off('pitchend', handleStartMenu);
    };
  }, []);

  return (
    <Menu
      open={!!contextMenuPosition}
      onClose={handleCloseMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuPosition
          ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={handleCopyCoordinates} ref={copyButtonRef}>{t('copyСoordinates')}</MenuItem>
    </Menu>
  );
}

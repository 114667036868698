import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'devextreme-react/data-grid';
import {
  formatPosition, getCategoryParam,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { requestTemporaries } from '../../common/utils/requestRoute';
import { getGroupName } from '../../common/components/SmartTable/funcs/calculateCellValue';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';
import { useAttributePreference, usePreference } from '../../common/utils/preferences';
import useReportStyles from './hooks/useReportStyles';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import { runLoadingResources } from '../../map/MapControllerDynamic';
import ReportTemplate from './components/ReportTemplate';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'detectedObjectReport';

const columnsArray = [];

const DetectedObjectsReportPage = () => {
  const category = 'detected_object';
  const t = useTranslation();
  const classes = useReportStyles();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const coordinateFormat = usePreference('coordinateFormat');
  const speedUnit = useAttributePreference('speedUnit');
  const history = useHistory();
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups.items);

  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [devicesObject, setDevicesObject] = useState({});
  const [lastSelected, setLastSelected] = useState({});

  const [selectedItems, setSelectedItems] = useState(null);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();
  const devicesAbortController = useRef();

  const templateField = useTemplatesFieldsPresetForReports({}, category, coordinateFormat, speedUnit);
  const reportColumns = useAutoCompleteColumns(templateField);

  useEffect(() => {
    const result = {};
    items.forEach((item) => {
      result[item.deviceId] = item;
    });
    setDevicesObject(result);
  }, [items]);

  const handleSubmit = async (deviceId, from, to, headers, hideLoadingForm) => {
    setItems([]);
    setProgressReport(0);
    const result = [];
    setProgress(1);
    await requestTemporaries(history, dispatch, result, from, to, reportAbortController,
      headers, setProgressReport, hideLoadingForm);
    setItems(result);
    setProgress(0);
  };

  const formatSmartValue = (key, caption, devices) => {
    switch (key) {
      case 'serverTime':
      case 'fixTime':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="datetime"
            caption={t(caption)}
          />
        );
      case 'uniqueId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.uniqueId}
            caption={t(caption)}
          />
        );
      case 'sourceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getCategoryParam(item, key)}
            caption={t(caption)}
          />
        );
      case 'deviceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.name}
            caption={t(caption)}
          />
        );
      case 'group':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getGroupName(devices[item.deviceId] ?? {}, groups)}
            caption={t(caption)}
          />
        );
      case 'longitude':
      case 'latitude':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'address':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'sector':
      case 'centerFrequency':
      case 'bandwidth':
      case 'averageSnr':
      case 'comment':
      case 'peleng':
      case 'dist':
      case 'zone':
        return (
          <Column
            key={key}
            name={`attributes.categoryParams.${key}`}
            dataField={`attributes.categoryParams.${key}`}
            calculateCellValue={(item) => (item.attributes.categoryParams ? (item.attributes.categoryParams[key] ?? '') : '')}
            caption={t(caption)}
          />
        );
      default:
        return (
          <Column
            dataField={key}
            caption={t(caption)}
            dataType="string"
          />
        );
    }
  };

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => () => {
    if (devicesAbortController.current) {
      devicesAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => runLoadingResources(), []);

  const handleDataSelect = (keys) => {
    const result = {};

    if (keys.length === 0) {
      setSelectedItems({});
      setLastSelected(null);
      return;
    }

    keys.forEach(async (item) => {
      const { deviceId } = item;

      if (!result[deviceId]) {
        result[deviceId] = [];
      }

      result[deviceId].push({
        deviceId,
        course: item.course,
        latitude: parseFloat(item.latitude),
        longitude: parseFloat(item.longitude),
        attributes: {},
      });
    });

    setSelectedItems(result);
    setLastSelected(keys[keys.length - 1]);
  };

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      selectedItems={selectedItems}
      setSelectedItem={setSelectedItems}
      handleDataSelect={handleDataSelect}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="fixTime"
      formatValue={formatSmartValue}
      reportColumns={reportColumns}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      breadcrumbs={['reportTitle', 'reportDetectedObject']}
      withoutDevices
      progressReport={progressReport}
      map={selectedItems && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <PositionsMap
              positions={selectedItems}
              devices={devicesObject}
              data={positionsTypes.current}
              noClick
            />
            {lastSelected && <MapCamera latitude={lastSelected.latitude} longitude={lastSelected.longitude} deviceId={`${lastSelected.latitude}-${lastSelected.longitude}`} />}
          </Map>
        </div>
      )}
    />
  );
};

export default DetectedObjectsReportPage;

/* eslint-disable object-curly-newline */
import React from 'react';
import { ColorsCellRender, IconCellRender } from '../../columns/cells';
import { ColorsGroupCellRender, TextIconGroupCellRender } from '../../columns/groups';
import {
  getTextForIconOrDefaultValue, getTextFromIcon, getTextFromIconWithSeparator,
  translateDeviceModelFromItem,
} from '../../funcs/calculateCellValue';
import baseProperties from '../shared/baseProperties';
import { getStringFromBoolean } from '../../funcs/utils';
import presetsColumns from '../shared/presetsColumns';

export default {
  calculateValues: {
    positionsShared: ({ groups, devices }) => [
      {
        ...presetsColumns.common.name,
        ...baseProperties,
        calculateCellValue: (item) => devices[item.deviceId]?.name,
        calculateGroupValue: (item) => devices[item.deviceId]?.name,
      },
      {
        ...presetsColumns.base.groupName,
        ...baseProperties,
        calculateCellValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
        calculateGroupValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
      },
    ],
    devicesShared: ({ devicesObject, groups, t, theme, devices }) => [
      {
        ...presetsColumns.common.uniqueId,
        ...baseProperties,
        calculateCellValue: (item) => devicesObject[item.deviceId]?.uniqueId,
        calculateGroupValue: (item) => devicesObject[item.deviceId]?.uniqueId,
      },
      {
        ...presetsColumns.common.name,
        ...baseProperties,
        calculateCellValue: (item) => devices[item.deviceId]?.name,
        calculateGroupValue: (item) => devices[item.deviceId]?.name,
      },
      {
        ...presetsColumns.base.groupName,
        ...baseProperties,
        calculateCellValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
        calculateGroupValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
      },
      {
        ...presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
        ...baseProperties,
        cellComponent: ({ data }) => <IconCellRender dataset={{ data: devices[data.data.deviceId] }} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(devices[item.deviceId], t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(devices[item.deviceId], t),
        calculateDisplayValue: (item) => getTextFromIcon(devices[item.deviceId], t),
      },
      {
        ...presetsColumns.calculateValues.deviceColor({ theme }),
        ...baseProperties,
        cellComponent: ({ data }) => <ColorsCellRender dataset={{ data: devices[data.data.deviceId] }} theme={theme} />,
        groupCellComponent: ({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />,
        calculateCellValue: (item) => getTextForIconOrDefaultValue(devices[item.deviceId], theme),
        calculateGroupValue: (item) => getTextForIconOrDefaultValue(devices[item.deviceId], theme),
      },
    ],
    byCategory: {
      route: ({ t, coordinateFormat, speedUnit }) => [
        {
          ...presetsColumns.calculateValues.category({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.latitude({ coordinateFormat }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.longitude({ coordinateFormat }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.altitude,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.address,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.valid({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.speed({ speedUnit, t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.visibleSat,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.mccMnc,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.lacCid,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.visibleCids,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.accuracy({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.motion({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.powerLine,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.batteryLevel({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.protocol,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.message,
          ...baseProperties,
        },
      ],
      trip: ({ t, coordinateFormat, speedUnit, distanceUnit }) => [
        {
          ...presetsColumns.common.startTime,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.startCoordinates(({ coordinateFormat, t })),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.startAddress,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.endTime,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.endCoordinates({ coordinateFormat, t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.endAddress,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.distance({ distanceUnit, t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.averageSpeed({ speedUnit, t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.maxSpeed({ speedUnit, t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.duration({ t }),
          ...baseProperties,
        },
      ],
      stop: ({ t, coordinateFormat }) => [
        {
          ...presetsColumns.common.startTime,
        },
        {
          ...presetsColumns.common.endTime,
        },
        {
          ...presetsColumns.common.address,
        },
        {
          ...presetsColumns.common.duration({ t }),
        },
        {
          ...presetsColumns.common.latitude({ coordinateFormat }),
        },
        {
          ...presetsColumns.common.longitude({ coordinateFormat }),
        },
      ],
      event: ({ t, geofences }) => [
        {
          ...presetsColumns.common.eventTime,
        },
        {
          ...presetsColumns.common.eventType({ t }),
        },
        {
          ...presetsColumns.common.geofence({ geofences }),
        },
        {
          ...presetsColumns.common.message,
        },
      ],
      sms: ({ groups, devices, t, users }) => [
        {
          ...presetsColumns.common.name,
          ...baseProperties,
          calculateCellValue: (item) => devices[item.deviceId]?.name,
          calculateGroupValue: (item) => devices[item.deviceId]?.name,
        },
        {
          ...presetsColumns.base.groupName,
          ...baseProperties,
          caption: 'reportSmsGroup',
          calculateCellValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
          calculateGroupValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
          visible: false,
        },
        {
          ...presetsColumns.common.smsTime,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.direct({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.sender({ devices, users }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.senderPhone,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.recipient({ devices, users }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.recipientPhone,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.smsMessage,
        },
        {
          ...presetsColumns.common.success,
          ...baseProperties,
        },
      ],
      log: ({ t, devices }) => [
        {
          ...presetsColumns.common.level({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.name,
          ...baseProperties,
          calculateCellValue: (item) => devices[item.deviceId]?.name,
          calculateGroupValue: (item) => devices[item.deviceId]?.name,
        },
        {
          ...presetsColumns.common.logTime,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.protocol,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.directLog({ t }),
          ...baseProperties,
        },
        {
          ...presetsColumns.common.remoteAddress,
          ...baseProperties,
        },
        {
          ...presetsColumns.common.smsMessage,
        },
      ],
      radarAndAntiUav: ({ devices, t, positions }) => [
        {
          ...baseProperties,
          name: 'deviceModel',
          caption: 'deviceModel',
          calculateCellValue: (item) => translateDeviceModelFromItem(item, t, devices),
          calculateGroupValue: (item) => translateDeviceModelFromItem(item, t, devices),
        },
        {
          ...presetsColumns.calculateValues.deviceConnectionStatus.text({ t }),
          ...baseProperties,
        },
        {
          ...baseProperties,
          name: 'positionConnectionStatusDescription',
          caption: 'positionConnectionStatusDescription',
          calculateCellValue: (item) => positions[item.deviceId]?.attributes?.categoryParams?.connectionStatusDescription,
          calculateGroupValue: (item) => positions[item.deviceId]?.attributes?.categoryParams?.connectionStatusDescription,
        },
        {
          dataField: 'attributes.categoryParams.showRadarArea',
          name: 'deviceShowRadarArea',
          caption: 'deviceShowRadarArea',
          visible: false,
          calculateCellValue: (item) => getStringFromBoolean(item.attributes?.categoryParams?.showRadarArea ?? false, t),
          calculateGroupValue: (item) => getStringFromBoolean(item.attributes?.categoryParams?.showRadarArea ?? false, t),
        },
      ],
    },
    deviceSharedSlim: ({ groups, t, theme }) => [
      {
        ...presetsColumns.common.uniqueId,
        ...baseProperties,
      },
      {
        ...presetsColumns.common.name,
        ...baseProperties,
      },
      {
        ...presetsColumns.base.groupName,
        ...baseProperties,
        calculateCellValue: (item) => groups[item.groupId]?.name,
        calculateGroupValue: (item) => groups[item.groupId]?.name,
      },
      {
        ...presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
        ...baseProperties,
        cellComponent: ({ data }) => <IconCellRender dataset={{ data: data.data }} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(item, t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(item, t),
        calculateDisplayValue: (item) => getTextFromIcon(item, t),
      },
      {
        ...presetsColumns.calculateValues.deviceColor({ theme }),
        ...baseProperties,
        cellComponent: ({ data }) => <ColorsCellRender dataset={{ data: data.data }} theme={theme} />,
        groupCellComponent: ({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />,
        calculateCellValue: (item) => getTextForIconOrDefaultValue(item, theme),
        calculateGroupValue: (item) => getTextForIconOrDefaultValue(item, theme),
      },
    ],
  },
  serverField: [
    presetsColumns.serverField.serverTime,
    presetsColumns.serverField.fixTime,
  ],
};

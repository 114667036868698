import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import AdjustIcon from '@material-ui/icons/Adjust';
import SmsIcon from '@material-ui/icons/Sms';
import EventIcon from '@material-ui/icons/Event';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import GestureIcon from '@material-ui/icons/Gesture';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import StorageIcon from '@material-ui/icons/Storage';
import {
  Album, AllInbox, CameraRoll, CenterFocusStrongOutlined,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { getIsAdmin } from '../../../common/utils/selectors';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItem button key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  selected: PropTypes.bool.isRequired,
};

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const isAdmin = useSelector(getIsAdmin);
  const accessibleCategoriesByDetected = useSelector((state) => state.devices.accessibleCategoriesByDetected);

  const isAvailableVcam = accessibleCategoriesByDetected.includes('vcam');

  const devicesDetectedPages = {
    detected_object: <MenuItem
      key="detected_object"
      title={t('reportDetectedObject')}
      link="/reports/detected"
      icon={<AdjustIcon />}
      selected={location.pathname === '/reports/detected'}
    />,
    radar: <MenuItem
      key="radar"
      title={t('reportRadars')}
      link="/reports/radar"
      icon={<TrackChangesIcon />}
      selected={location.pathname === '/reports/radar'}
    />,
    anti_uav: <MenuItem
      key="anti_uav"
      title={t('reportAntiUav')}
      link="/reports/antiuav"
      icon={<Album />}
      selected={location.pathname === '/reports/antiuav'}
    />,
  };

  return (
    <>
      <List>
        <MenuItem
          title={t('reportPositions')}
          link="/reports/route"
          icon={<RoomIcon />}
          selected={location.pathname === '/reports/route'}
        />
        {accessibleCategoriesByDetected.map((category) => devicesDetectedPages[category])}
        <MenuItem
          title={t('reportTrips')}
          link="/reports/trip"
          icon={<GestureIcon />}
          selected={location.pathname === '/reports/trip'}
        />
        <MenuItem
          title={t('reportStops')}
          link="/reports/stop"
          icon={<LocalParkingIcon />}
          selected={location.pathname === '/reports/stop'}
        />
        <MenuItem
          title={t('reportEvents')}
          link="/reports/event"
          icon={<EventIcon />}
          selected={location.pathname === '/reports/event'}
        />
        <MenuItem
          title={t('reportSms')}
          link="/reports/sms"
          icon={<SmsIcon />}
          selected={location.pathname === '/reports/sms'}
        />
        {isAvailableVcam && (
          <>
            <MenuItem
              title={t('reportVideoArchive')}
              link="/reports/records/archive"
              icon={<AllInbox />}
              selected={location.pathname === '/reports/records/archive'}
            />
            <MenuItem
              title={t('reportEventsRecording')}
              link="/reports/records/event"
              icon={<CenterFocusStrongOutlined />}
              selected={location.pathname === '/reports/records/event'}
            />
            <MenuItem
              title={t('reportVideoCameraRecording')}
              link="/reports/records/export"
              icon={<CameraRoll />}
              selected={location.pathname === '/reports/records/export'}
            />
          </>
        )}
        {/* <MenuItem
          title={t('reportStat')}
          link="/reports/stat"
          icon={<AssessmentIcon />}
          selected={location.pathname === '/reports/stat'}
        /> */}
        {isAdmin && (
          <>
            <Divider />
            <MenuItem
              title={t('reportLogs')}
              link="/reports/log"
              icon={<StorageIcon />}
              selected={location.pathname === '/reports/log'}
            />
          </>
        )}

      </List>
    </>
  );
};

export default ReportsMenu;

import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, TextField, Typography, makeStyles, Modal,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { getAttr, setAttr } from '../../../common/utils/formatter';

const useStyles = makeStyles((theme) => ({
  details: {
    flexDirection: 'column',
  },
  addButton: {
    width: '100%',
    backgroundColor: '#9cd26d',
  },
  protocolBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const ProtocolsBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [newProtocol, setNewProtocol] = useState('');
  const [error, setError] = useState('');

  const protocols = getAttr(item, 'protocols') || [];

  const handleOpenModal = () => {
    setOpenModal(true);
    setNewProtocol('');
    setError('');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddProtocol = () => {
    if (newProtocol.trim() === '') {
      setError(t('settingsProtocolNameRequired'));
      return;
    }

    if (protocols.includes(newProtocol.trim())) {
      setError(t('settingsProtocolNameUnique'));
      return;
    }

    const updatedProtocols = [...protocols, newProtocol.trim()];
    setAttr(setItem, 'protocols', updatedProtocols);
    handleCloseModal();
  };

  const handleDeleteProtocol = (index) => {
    const updatedProtocols = protocols.filter((_, i) => i !== index);
    setAttr(setItem, 'protocols', updatedProtocols);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('settingsProtocols')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {protocols.map((protocol, index) => (
          <Box key={`procols${index + 1}`} className={classes.protocolBox}>
            <Typography>{protocol}</Typography>
            <IconButton onClick={() => handleDeleteProtocol(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}

        <Button
          variant="outlined"
          className={classes.addButton}
          onClick={handleOpenModal}
        >
          {t('settingsAddProtocol')}
        </Button>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box className={classes.modalContent}>
            <IconButton className={classes.closeButton} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{t('settingsAddProtocol')}</Typography>
            <TextField
              label={t('settingsProtocolName')}
              variant="filled"
              fullWidth
              value={newProtocol}
              onChange={(e) => setNewProtocol(e.target.value)}
              error={!!error}
              helperText={error}
              margin="normal"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAddProtocol}
              style={{ marginTop: 8 }}
            >
              {t('sharedAdd')}
            </Button>
          </Box>
        </Modal>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProtocolsBlock;

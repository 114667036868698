import theme from '../theme';

export default {
  device: {
    model: {
      default: {
        fields: {},
        writen: {},
      },
    },
  },
  uav: {
    model: {
      default: {
        fields: {},
        writen: {},
      },
    },
  },
  radar: {
    model: {
      default: {
        fields: {
          azimuth: 'number',
          diagram: 'number',
          radius: 'number',
          radarColor: 'string',
          showRadarArea: 'boolean',
          externalId: 'number',
          connectionStatus: {
            disconnected: 'connectionStatusDisconnected',
            connected: 'connectionStatusConnected',
            error: 'connectionStatusError',
          },
          connectionStatusDescription: 'string',
        },
        writen: {
          azimuth: 0,
          diagram: 0,
          radius: 0,
          radarColor: theme.palette.tracks.replay0,
          showRadarArea: true,
          externalId: 0,
        },
      },
      rubezh: {
        fields: {
          azimuth: 'number',
          diagram: 'number',
          radius: 'number',
          radarColor: 'string',
          showRadarArea: 'boolean',
          externalId: 'number',
          connectionStatus: {
            disconnected: 'connectionStatusDisconnected',
            connected: 'connectionStatusConnected',
            error: 'connectionStatusError',
          },
          connectionStatusDescription: 'string',
          mti: 'number',
          ip: 'string',
          dataPort: 'number',
          controlPort: 'number',
        },
        writen: {
          azimuth: 0,
          diagram: 0,
          radius: 0,
          radarColor: theme.palette.tracks.replay0,
          showRadarArea: true,
          externalId: 0,
          mti: 0,
          ip: '',
          dataPort: 1000,
          controlPort: 1000,
        },
      },
      shade: {
        fields: {
          azimuth: 'number',
          diagram: 'number',
          radius: 'number',
          radarColor: 'string',
          showRadarArea: 'boolean',
          externalId: 'number',
          connectionStatus: {
            disconnected: 'connectionStatusDisconnected',
            connected: 'connectionStatusConnected',
            error: 'connectionStatusError',
          },
          connectionStatusDescription: 'string',
          mti: 'number',
          ip: 'string',
          dataPort: 'number',
        },
        writen: {
          azimuth: 0,
          diagram: 0,
          radius: 0,
          radarColor: theme.palette.tracks.replay0,
          showRadarArea: true,
          externalId: 0,
          mti: 0,
          ip: '',
          dataPort: 1000,
        },
      },
      globus: {
        fields: {
          azimuth: 'number',
          diagram: 'number',
          radius: 'number',
          radarColor: 'string',
          showRadarArea: 'boolean',
          externalId: 'number',
          connectionStatus: {
            disconnected: 'connectionStatusDisconnected',
            connected: 'connectionStatusConnected',
            error: 'connectionStatusError',
          },
          connectionStatusDescription: 'string',
          ip: 'string',
          port: 'number',
          elevationAngle: 'number',
          periodicMessages: 'boolean',
        },
        writen: {
          azimuth: 0,
          diagram: 0,
          radius: 0,
          radarColor: theme.palette.tracks.replay0,
          showRadarArea: true,
          externalId: 0,
          ip: '',
          port: 1000,
          elevationAngle: 0,
          periodicMessages: false,
        },
      },
    },
  },
  anti_uav: {
    model: {
      default: {
        fields: {
          connectionStatus: {
            disconnected: 'connectionStatusDisconnected',
            connected: 'connectionStatusConnected',
            error: 'connectionStatusError',
          },
          connectionStatusDescription: 'string',
          workMode: {
            unknown: 'workModeUnknown',
            detection: 'workModeDetection',
            suppression: 'workModeSuppression',
          },
          workModeDescription: 'string',
        },
        writen: {
          workMode: 'detection',
        },
      },
      strizh3: {
        fields: {
          connectionStatus: {
            disconnected: 'connectionStatusDisconnected',
            connected: 'connectionStatusConnected',
            error: 'connectionStatusError',
          },
          connectionStatusDescription: 'string',
          workMode: {
            unknown: 'workModeUnknown',
            detection: 'workModeDetection',
            suppression: 'workModeSuppression',
            idle: 'workModeIdle',
          },
          workModeDescription: 'string',
          ip: 'string',
          port: 'number',
          radius: 'number',
          azimuth: 'number',
          controlMode: {
            manual: 'controlModeManual',
            auto: 'controlModeAuto',
            auto_with_delay: 'controlModeAutoWithDelay',
          },
          numberOfHosts: 'number',
          sectorState0: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
          sectorState1: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
          sectorState2: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
          sectorState3: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
          sectorState4: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
          sectorState5: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
          sectorState6: {
            unknown: 'sectorStateUnknown',
            signals_detected: 'sectorStateSignalsDetected',
            no_signals: 'sectorStateNoSignals',
          },
        },
        writen: {
          workMode: 'detection',
          numberOfHosts: 1,
          ip0: '0.0.0.0',
          port0: 1000,
          ip1: '0.0.0.0',
          port1: 1001,
          radius0: 0,
          radius1: 0,
          azimuth0: 0,
          azimuth1: 0,
          controlMode: 'auto',
        },
      },
    },
  },
  detectedObjects: {
    model: {
      default: {
        fileds: {
          sourceId: 'number',
        },
        writen: {},
      },
    },
  },
  vcam: {
    model: {
      default: {
        fields: {
          videoRecordService: 'string',
          videoCameraName: 'string',
          azimuth: 'number',
          diagram: 'number',
          radius: 'number',
          videoCameraColor: 'string',
          showVideoCameraArea: 'boolean',
        },
        writen: {
          videoRecordService: '',
          videoCameraName: '',
          azimuth: 0,
          diagram: 0,
          radius: 0,
          videoCameraColor: theme.palette.tracks.replay0,
          showVideoCameraArea: true,
        },
      },
    },
  },
};

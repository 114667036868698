import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Backdrop, Button, Fade, FormControl, Modal, Paper, Typography, makeStyles,
} from '@material-ui/core';
import logout from '../utils/logout';
import { useTranslation } from './LocalizationProvider';

const useStyle = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  text: {
    marginTop: theme.spacing(1.5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const ConfirmExit = ({ open, setOpen }) => {
  const classes = useStyle();
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleYes = async () => {
    logout(history, dispatch);
    setOpen(false);
    await fetch('/api/session', { method: 'DELETE' });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center" className={classes.text}>
            Выйти из приложения?
          </Typography>
          <FormControl fullWidth margin="normal">
            <div className={classes.buttons}>
              <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                {t('sharedCancel')}
              </Button>
              <Button type="button" color="primary" variant="contained" onClick={handleYes}>
                {t('yes')}
              </Button>
            </div>
          </FormControl>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ConfirmExit;

/* eslint-disable no-empty-pattern */
import { useCallback, useRef } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import { createVideoCameraMetadata, isVideoCamera, isCorrectVideoCameraItem } from '../utils/videoCameraHelper';

const VideoCameraLayer = ({ }) => {
  const videoRecordServices = useRef(new Set());

  const videoCameraUniqueIds = useRef(new Set());
  const allowedVideoCameraNames = useRef([]);
  const videoCamerasMetadata = useRef({});

  const getVideoRecordServices = () => new Set(videoRecordServices.current);

  const getVideoCameraUniqueIds = () => new Set(videoCameraUniqueIds.current);
  const getAllowedVideoCameraNames = () => [...allowedVideoCameraNames.current];
  const getVideoCamerasMetadata = () => ({ ...videoCamerasMetadata.current });

  const clearResources = () => {
    videoRecordServices.current = new Set();
    videoCameraUniqueIds.current = new Set();
    allowedVideoCameraNames.current = [];
    videoCamerasMetadata.current = {};
  };

  const loadVideoCameras = useCallback(async () => {
    clearResources();

    const response = await fetch('/api/devices');
    const data = await response.json();

    for (const item of Object.values(data)) {
      if (!isVideoCamera(item)) {
        continue;
      }

      if (isCorrectVideoCameraItem(item)) {
        const cameraMetadata = createVideoCameraMetadata(item);

        videoCamerasMetadata.current[cameraMetadata.id] = { ...cameraMetadata };
        videoCameraUniqueIds.current.add(cameraMetadata.id);

        videoRecordServices.current.add(cameraMetadata.videoRecordService);
        allowedVideoCameraNames.current.push(cameraMetadata.nameDisplayed);
      }
    }
  }, []);

  return {
    loadVideoCameras, getVideoRecordServices, getVideoCameraUniqueIds, getAllowedVideoCameraNames, getVideoCamerasMetadata,
  };
};

export default VideoCameraLayer;

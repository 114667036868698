import { isUndefinedOrNull } from './stringUtils';

export const isVcam = (item) => {
  if (isUndefinedOrNull(item)) {
    return false;
  }

  if (!isUndefinedOrNull(item.deviceCategory)) {
    return item.deviceCategory === 'vcam';
  }
  if (!isUndefinedOrNull(item.category)) {
    return item.category === 'vcam';
  }

  return false;
};

export const tryGetVcamPosition = (positionData, devicesList) => {
  if (!isVcam(positionData)) {
    return positionData;
  }

  let newPositionData = { ...positionData };

  const foundDevice = devicesList[newPositionData.deviceId];
  if (!isUndefinedOrNull(foundDevice) && !isUndefinedOrNull(foundDevice.attributes?.categoryParams)) {
    const categoryParams = { ...foundDevice.attributes.categoryParams };
    newPositionData = { ...newPositionData, categoryParams };
  }

  return newPositionData;
};

export const isProtocolWithCertificate = () => {
  let result = false;

  try {
    // eslint-disable-next-line no-restricted-globals
    if (location.protocol === 'https:') {
      result = true;
    }
  } catch (error) {
    console.log(error);
  }

  return result;
};

export const getWsProtocolString = () => (isProtocolWithCertificate() ? 'wss' : 'ws');
export const getHttpProtocolString = () => (isProtocolWithCertificate() ? 'https' : 'http');

/**
 * Возвращает url адрес для websocket.
 * @param {*} item Объект, который содержит информацию.
 */
export const getJsMpegUrl = (item) => {
  if (!isVcam(item) || isUndefinedOrNull(item?.attributes?.categoryParams?.videoRecordService)) {
    return undefined;
  }

  const { videoRecordService, videoCameraName } = item.attributes.categoryParams;
  // Получаем адрес сервиса без http протокола
  const [, address] = videoRecordService.split('://');

  const wsProtocol = getWsProtocolString();

  return `${wsProtocol}://${address}/live/jsmpeg/${videoCameraName}`;
};

/* eslint-disable object-curly-newline */

import presetsColumns from '../shared/presetsColumns';

export default ({ t }) => [
  presetsColumns.common.id,
  presetsColumns.common.userName,
  presetsColumns.calculateValues.accountType({ t }),
  presetsColumns.calculateValues.accountType({ t }),
  presetsColumns.common.login,
  presetsColumns.common.email,
];

import React from 'react';
import {
  List, ListItem, ListItemText, ListItemIcon, Divider, ListSubheader,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SideNav = ({ routes }) => {
  const location = useLocation();
  const sessionAllowed = useSelector((state) => state.session.sessionAllowed);
  const user = useSelector((state) => state.session.user);

  const isDisable = (route) => {
    if (sessionAllowed !== false) {
      return false;
    }

    if (user?.administrator && route.alwaysEnable) {
      return false;
    }
    return true;
  };

  const generateListItem = (route) => {
    if (route.subheader) {
      return (
        <div key={route.subheader}>
          <Divider />
          <ListSubheader>{route.subheader}</ListSubheader>
        </div>
      );
    }
    if (route.href) {
      return (
        <ListItem
          disableRipple
          component={Link}
          key={route.href}
          button
          to={route.href}
          selected={!!location.pathname.match(route.match || route.href)}
          disabled={isDisable(route)}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={route.name} />
        </ListItem>
      );
    }
    return (
      <ListItem
        disableRipple
        key={route.match}
        onClick={route.onClick}
        button
        selected={!!location.pathname.match(route.match)}
      >
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText primary={route.name} />
      </ListItem>
    );
  };

  return (
    <List disablePadding style={{ paddingTop: '16px' }}>
      {routes.map(generateListItem)}
    </List>
  );
};

export default SideNav;

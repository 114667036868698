import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  abortRequest, collectReport,
} from '../../common/utils/formatter';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { errorsActions } from '../../store';
import { usePreference } from '../../common/utils/preferences';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';

const typeReport = 'eventReport';

const columnsArray = [];

const events = {
  // allEvents: 'eventAll',
  deviceOnline: 'eventDeviceOnline',
  deviceUnknown: 'eventDeviceUnknown',
  deviceOffline: 'eventDeviceOffline',
  deviceInactive: 'eventDeviceInactive',
  deviceMoving: 'eventDeviceMoving',
  deviceStopped: 'eventDeviceStopped',
  geofenceEnter: 'eventGeofenceEnter',
  geofenceExit: 'eventGeofenceExit',
  textMessage: 'eventTextMessage',
  alarm: 'eventAlarm',
};

const EventReportPage = () => {
  const category = 'event';
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const coordinateFormat = usePreference('coordinateFormat');

  const [items, setItems] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [page, setPage] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();

  const templateField = useTemplatesFieldsPresetForReports(devicesObject, category, coordinateFormat);
  const reportColumns = useAutoCompleteColumns(templateField);

  const handleSubmit = (deviceId, from, to, headers, hideLoadingForm) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        deviceId.forEach((it) => query.append('deviceId', it));
        eventTypes.forEach((it) => query.append('type', it));
        setProgress(1);
        const response = await fetch(`/api/reports/events?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              hideLoadingForm();
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);
        hideLoadingForm();
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgress(0);
        hideLoadingForm();
      }
    };
    apiCall();
  };

  const chooseEvents = (values) => {
    if (values[values.length - 1] === 'all') {
      if (eventTypes.length) {
        setEventTypes([]);
      } else {
        setEventTypes(Object.keys(events));
      }
    } else {
      setEventTypes(values.filter((v) => v));
    }
  };

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          setProgressDevices(true);
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);

            const resultObj = {};
            result.forEach((item) => resultObj[item.id] = item);
            setDevicesObject(resultObj);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
          setProgressDevices(false);
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      page={page}
      reportColumns={reportColumns}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      breadcrumbs={['reportTitle', 'reportEvents']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      countProgress={countProgress}
      progressReport={progressReport}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      events={events}
      eventTypes={eventTypes}
      chooseEvents={chooseEvents}
    />
  );
};

export default EventReportPage;

import { getLastMatch } from '../../../common/utils/stringUtils';
import { getJsMpegUrl } from '../../../common/utils/vcamHelper';

export const isVideoCamera = (item) => item.category === 'vcam';
export const isCorrectVideoCameraItem = (item) => (
  item.attributes?.categoryParams?.videoRecordService && item.attributes?.categoryParams?.videoCameraName);

export const getNameDisplayed = (videoItem) => `${videoItem.name} (${videoItem.attributes.categoryParams.videoCameraName})`;
// export const getNameDisplayed = (videoItem) => videoItem.name;
export const getVideoCameraId = (videoItem) => `${videoItem.uniqueId}${videoItem.id}`;

export const createVideoCameraMetadata = (item) => {
  const { name, id } = item;
  const { videoCameraName, videoRecordService } = item.attributes.categoryParams;
  const cameraId = getVideoCameraId(item);
  const nameDisplayed = getNameDisplayed(item);
  const jsmpegStream = getJsMpegUrl(item);

  return {
    id: cameraId,
    deviceId: id,
    deviceName: name,
    videoCameraName,
    videoRecordService,
    nameDisplayed,
    jsmpegStream,
  };
};

export const formatVideoCameraDate = (datetime) => datetime.format('DD.MM.YYYY HH:mm:ss');
export const formatVideoCameraDatetime = (datetime) => datetime.format('dd DD.MM.YYYY HH:mm:ss');

export const createVideoCameraNameWithTime = (videoCameraName, from, to) => `${videoCameraName} ${formatVideoCameraDate(from)} - ${formatVideoCameraDate(to)}`;

export const createVideoUrlForM3U8 = (videoItem) => `${videoItem.videoRecordService}/vod/${videoItem.camera}/start/${videoItem.start_time}/end/${videoItem.end_time}/index.m3u8`;

// Возвращает имя камеры из текста отображаемый в формате: имя устройства (имя камеры).
// Регулярное выражение для поиска текста, заключённого в скобки.
// Метод возвращает последнее совпадение, независимо от того, сколько скобок расположено перед ним.
export const getCameraNameFromNameDisplayed = (text) => getLastMatch(text, '(?<=\\().*?(?=\\))');

import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import MultiSelectDropdown from '../../common/form/MultiSelectDropdown';
import { getAvalibleProtocols } from '../../common/utils/formatter';

const useStyles = makeStyles((theme) => ({
  details: {
    flexDirection: 'column',
  },
  textArea: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgba(0, 0, 0, 0.035)',
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused': {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(12px, 8px) scale(0.75)',
    },
  },
}));

const SmsTemplate = () => {
  const classes = useStyles();
  const t = useTranslation();
  const protocols = useSelector((state) => state.session.server.attributes.protocols);

  const [item, setItem] = useState();

  return (
    <EditItemView
      endpoint="smsTemplates"
      item={item}
      setItem={setItem}
      disabledSave={!item?.name}
    >
      {item
        && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('settingsSmsTemplate')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                required
                margin="normal"
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('settingsSmsTemplateName')}
                variant="filled"
              />
              <MultiSelectDropdown
                label={t('deviceCommunicationProtocols')}
                value={getAvalibleProtocols(item.protocols, protocols) || []}
                setValue={(newValue) => setItem({ ...item, protocols: newValue })}
                data={protocols}
                emptyText={t('settingsSmsTemplatesAllDevices')}
              />
              <TextField
                required
                margin="normal"
                value={item.template || ''}
                onChange={(event) => setItem({ ...item, template: event.target.value })}
                label={t('settingsSmsTemplateText')}
                variant="filled"
              />
              <TextField
                className={classes.textArea}
                fullWidth
                label={t('settingsDescription')}
                value={item.description || ''}
                onChange={(event) => setItem({ ...item, description: event.target.value })}
                variant="outlined"
                multiline
                minRows={10}
                maxRows={10}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.035)' }}
              />
            </AccordionDetails>
          </Accordion>
        )}
    </EditItemView>
  );
};

export default SmsTemplate;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { collectReport, abortRequest } from '../../common/utils/formatter';
import { useAttributePreference, usePreference } from '../../common/utils/preferences';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import { runLoadingResources } from '../../map/MapControllerDynamic';
import { errorsActions } from '../../store';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'routeReport';

// Список колонок [имя поля в json с бэкенда, имя поля в файле переводчике, возможность сортировки (0 - нет, 1 - да)]
const columnsArray = [];

const RouteReportPage = () => {
  const category = 'route';
  const classes = useReportStyles();
  const speedUnit = useAttributePreference('speedUnit');
  const coordinateFormat = usePreference('coordinateFormat');
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});
  const devicesObjectRef = useRef(devicesObject);
  const [stateSyncDevices, setStateSyncDevices] = useState(new Date().getTime());
  const [lastSelected, setLastSelected] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [page, setPage] = useState(0);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);
  const reportAbortController = useRef();

  const templateField = useTemplatesFieldsPresetForReports(devicesObject, category, coordinateFormat, speedUnit);
  const reportColumns = useAutoCompleteColumns(templateField);

  useEffect(() => {
    const result = {};
    devicesList.forEach((item) => {
      result[item.id] = item;
      result[item.id].attributes = {};
    });
    setDevicesObject(result);
  }, [devicesList]);

  const handleSubmit = (deviceId, from, to, headers, hideLoadingForm) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;

    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        deviceId.forEach((it) => query.append('deviceId', it));
        query.append('requestName', requestName);
        setProgress(1);
        const response = await fetch(`/api/reports/route?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              hideLoadingForm();
              for (const item of result) {
                item.categoryFromPosition = 'z';
              }
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);
        hideLoadingForm();
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgress(0);
        hideLoadingForm();
      }
    };
    apiCall();
  };

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          setProgressDevices(true);
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);
            // setDevicesObject(result);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
          setProgressDevices(false);
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => runLoadingResources(), []);

  useEffect(() => {
    devicesObjectRef.current = devicesObject;
    setStateSyncDevices(new Date().getTime());
  }, [devicesObject]);

  const handleDataSelect = (keys) => {
    const result = {};

    if (keys.length === 0) {
      setSelectedItems({});
      setLastSelected(null);
      return;
    }

    keys.forEach(async (item) => {
      const { deviceId } = item;

      if (!result[deviceId]) {
        result[deviceId] = [];
      }

      result[deviceId].push({
        deviceId,
        course: item.course,
        latitude: parseFloat(item.latitude),
        longitude: parseFloat(item.longitude),
        attributes: {},
      });

      // Проверяем цвет и иконку
      if (!devicesObjectRef.current[deviceId]?.icon || !devicesObjectRef.current[deviceId]?.attributes?.color) {
        try {
          const response = await fetch(`/api/devices/${deviceId}`);
          if (response.ok) {
            const data = await response.json();
            setDevicesObject((prevState) => {
              const updatedState = {
                ...prevState,
                [deviceId]: data,
              };
              devicesObjectRef.current = updatedState;
              return updatedState;
            });
          } else if (response.status === 401) {
            logout(history, dispatch);
          }
        } catch (error) {
          console.error(`Failed to fetch device data for ID ${deviceId}:`, error);
        }
      }
    });

    setSelectedItems(result);
    setLastSelected(keys[keys.length - 1]);
  };

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      setSelectedItems={setSelectedItems}
      columnsArray={columnsArray}
      handleDataSelect={handleDataSelect}
      handleSubmit={handleSubmit}
      page={page}
      reportColumns={reportColumns}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      breadcrumbs={['reportTitle', 'reportPositions']}
      devicesList={devicesList}
      devicesObject={devicesObjectRef.current}
      countProgress={countProgress}
      progressReport={progressReport}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      map={selectedItems && (
        <div className={classes.containerMap}>
          <Map noFixed disableOpacityMaps>
            <PositionsMap
              positions={selectedItems}
              devices={devicesObjectRef.current}
              data={positionsTypes.current}
              stateSyncDevices={stateSyncDevices}
              noClick
            />
            {lastSelected && <MapCamera latitude={lastSelected.latitude} longitude={lastSelected.longitude} deviceId={`${lastSelected.latitude}-${lastSelected.longitude}`} />}
          </Map>
        </div>
      )}
    />
  );
};

export default RouteReportPage;

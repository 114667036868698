import React from 'react';
import useComponentsController, { availableComponents } from '../../common/hooks/useComponentsController';

const VideoMonitoringPanelView = () => {
  const getComponent = useComponentsController();

  return (
    <>
      {getComponent(availableComponents.VideoMonitoringPanelComponent, { localStorageSectionName: 'page' })}
    </>
  );
};

export default VideoMonitoringPanelView;

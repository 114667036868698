import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from './Map';
import { getExtraMapId } from '../common/utils/formatter';
import { getBeforeId } from './funcs/pathFuncs';
import addGeojsonLayer from './funcs/addGeojsonLayer';
import removeGeojsonLayer from './funcs/removeGeojsonLayer';
import { devicesActions, mapActions, positionsActions } from '../store';
import theme from '../common/theme';
import lightenColor from './funcs/lightenColor';
import darkenColor from './funcs/darkenColor';
import handleFetchGeoJson from './funcs/handleFetchGeoJson';

let intrevalId;
let prevNeedToDownloadCount = -1;
let needToDownloadCount = 0;

const ExtraMap = ({ opacityMaps }) => {
  const extraMaps = useSelector((state) => state.session.server.attributes.extraMaps ?? []);
  const dispatch = useDispatch();
  const moveEndListenersRef = useRef({});
  const controllersRef = useRef({});

  const [loadedMaps, setLoadedMaps] = useState({});

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';
  const onClusterClick = (event) => {
    const features = map.queryRenderedFeatures(event.point);
    map.easeTo({
      center: features[0].geometry.coordinates,
      zoom: map.getZoom() + 1,
    });
  };

  const onFeatureClick = (event) => {
    const coordinates = event.features[0].geometry.coordinates.slice();
    const description = event.features[0].properties;
    function convertNumbersToStrings(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'number') {
            obj[key] = String(obj[key]);
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            convertNumbersToStrings(obj[key]);
          }
        }
      }
      return obj;
    }

    function formatCoordinates(coordinates) {
      if (typeof coordinates[0] === 'number' && typeof coordinates[1] === 'number') {
        return {
          longitude: coordinates[0],
          latitude: coordinates[1],
        };
      }

      if (Array.isArray(coordinates[0][0])) {
        let latSum = 0;
        let lonSum = 0;
        let totalPoints = 0;

        coordinates[0].forEach((point) => {
          latSum += point[1];
          lonSum += point[0];
          totalPoints++;
        });

        const centerLat = latSum / totalPoints;
        const centerLon = lonSum / totalPoints;

        return {
          longitude: centerLon,
          latitude: centerLat,
        };
      }

      return {
        longitude: 0,
        latitude: 0,
      };
    }
    const selectPositionData = {
      longitude: formatCoordinates(coordinates).longitude,
      latitude: formatCoordinates(coordinates).latitude,
      coordinates: `${formatCoordinates(coordinates).latitude}, ${formatCoordinates(coordinates).longitude}`,
      extraAttributes: convertNumbersToStrings(description),
      objClass: 'geoJson',
    };

    if (description.title) selectPositionData.name = description.title;
    if (description.altitude) selectPositionData.altitude = description.altitude;
    if (description.height) selectPositionData.height = description.height;

    dispatch(positionsActions.needToShowDataPanel(true));
    dispatch(devicesActions.deselect(null));
    dispatch(positionsActions.selectPosition(selectPositionData));
  };

  function getScheme(type) {
    if (!type) {
      return 'xyz';
    }
    switch (type) {
      case 'XYZ':
        return 'xyz';
      case 'TMS':
        return 'tms';
      default:
        return 'xyz';
    }
  }

  const incrementGeoJsonCount = () => {
    needToDownloadCount++;
  };
  const decrementGeoJsonCount = () => {
    needToDownloadCount--;
  };

  useEffect(() => {
    needToDownloadCount = 0;

    intrevalId = setInterval(() => {
      if (needToDownloadCount === prevNeedToDownloadCount) {
        return;
      }
      if (needToDownloadCount === 0) {
        dispatch(mapActions.extraMapLoaded(true));
      } else {
        dispatch(mapActions.extraMapLoaded(false));
      }
      prevNeedToDownloadCount = needToDownloadCount;
    }, 1000);
    const extraMapsReversed = [...extraMaps].reverse();

    extraMapsReversed.forEach(async (extraMap) => {
      if (extraMap.params.tiles.length === 0) {
        return;
      }
      const id = getExtraMapId(extraMap.id);

      if (extraMap.params.type === 'XYZ' || extraMap.params.type === 'TMS' || extraMap.params.type === undefined) {
        map.addSource(id, {
          type: 'raster',
          tiles: extraMap.params.tiles,
          tileSize: Number(extraMap.params.tileSize),
          scheme: getScheme(extraMap.params.type),
          attribution: extraMap.params.attribution ?? '',
        });

        map.addLayer({
          id,
          type: 'raster',
          source: id,
          layout: {
            visibility: 'none',
          },
          paint: {
            'raster-opacity': 0,
          },
        }, getBeforeId('extraLayers'));
      }

      if (extraMap.params.type === 'GeoJson') {
        controllersRef.current[id] = new AbortController();
        try {
          setLoadedMaps((prev) => ({
            ...prev,
            [id]: {
              show: false,
              cluster: opacityMaps[id]?.onCluster ?? extraMap.params.onCluster,
              autoRefresh: opacityMaps[id]?.autoRefresh ?? extraMap.params.autoRefresh,
            },
          }));

          const updateLoadedMaps = () => {
            setLoadedMaps((prev) => ({
              ...prev,
              [id]: {
                show: true,
                cluster: opacityMaps[id]?.onCluster ?? extraMap.params.onCluster,
                autoRefresh: opacityMaps[id]?.autoRefresh ?? extraMap.params.autoRefresh,
              },
            }));
          };

          addGeojsonLayer(
            map,
            id,
            onClusterClick,
            onFeatureClick,
            onMouseEnter,
            onMouseLeave,
            extraMap,
            moveEndListenersRef,
            controllersRef.current[id].signal,
            decrementGeoJsonCount,
            incrementGeoJsonCount,
            dispatch,
            opacityMaps[id]?.s,
            opacityMaps[id]?.onCluster,
            opacityMaps[id]?.autoRefresh,
            updateLoadedMaps,
          );
        } catch (error) {
          console.error(error);
        }
      }
    });

    return (() => {
      clearInterval(intrevalId);

      Object.keys(controllersRef.current).forEach((key) => {
        if (controllersRef.current[key]) {
          controllersRef.current[key].abort();
        }
      });
      controllersRef.current = {};

      Object.keys(moveEndListenersRef.current).forEach((key) => {
        if (moveEndListenersRef.current[key]) {
          moveEndListenersRef.current[key]();
        }
      });
      moveEndListenersRef.current = {};

      extraMaps.forEach((extraMap) => {
        const id = getExtraMapId(extraMap.id);
        if (extraMap.params.type === 'XYZ' || extraMap.params.type === 'TMS' || extraMap.params.type === undefined) {
          if (map.getLayer(id)) {
            map.removeLayer(id);
          }
        }
        if (extraMap.params.type === 'GeoJson') {
          const baseId = `extraMap-Geojson-${id}`;
          removeGeojsonLayer(map, baseId, onClusterClick, onFeatureClick, onMouseEnter, onMouseLeave);
        }
        if (map.getSource(id)) {
          map.removeSource(id);
        }
      });
    });
  }, [extraMaps]);

  function setLayerProperties(map, isVisibility, layerId, paintProp, paintValue) {
    if (map.getLayer(layerId)) {
      const visibility = isVisibility ? 'visible' : 'none';
      map.setLayoutProperty(layerId, 'visibility', visibility);
      if (paintProp && paintValue !== undefined) {
        map.setPaintProperty(layerId, paintProp, paintValue);
      }
    }
  }

  useEffect(() => {
    const extraMapsReversed = [...extraMaps].reverse();

    extraMapsReversed.forEach(async (extraMap) => {
      const extraMapId = getExtraMapId(extraMap.id);

      const opacityMap = opacityMaps[extraMapId];
      if (extraMap.params.type === 'GeoJson') {
        const updateLoadedMaps = () => {
          setLoadedMaps((prev) => ({
            ...prev,
            [extraMapId]: {
              show: true,
              cluster: opacityMaps[extraMapId]?.onCluster ?? extraMap.params.onCluster,
              autoRefresh: opacityMaps[extraMapId]?.autoRefresh ?? extraMap.params.autoRefresh,
            },
          }));
        };

        const handleAbortAndCleanup = (extraMapId, controllersRef, moveEndListenersRef) => {
          if (controllersRef.current[extraMapId]) {
            controllersRef.current[extraMapId].abort();
          }
          if (moveEndListenersRef.current[extraMapId]) {
            moveEndListenersRef.current[extraMapId]();
            delete moveEndListenersRef.current[extraMapId];
          }
        };

        const isNeedToUpdate = () => loadedMaps[extraMapId]?.show === false // при старте не была загружена
          || (loadedMaps[extraMapId]?.cluster !== undefined // поменялсь настройка класстера
            && opacityMap?.onCluster !== undefined
            && opacityMap?.onCluster !== loadedMaps[extraMapId]?.cluster)
          || (loadedMaps[extraMapId]?.autoRefresh !== undefined // поменялась настройка автообновления
            && opacityMap?.autoRefresh !== undefined
            && opacityMap?.autoRefresh !== loadedMaps[extraMapId]?.autoRefresh);

        if (opacityMap?.s === true) {
          if (isNeedToUpdate()) {
            const url = extraMap.params.tiles[0];
            // проверка на необходимость автообновления
            const autoRefresh = (opacityMaps[extraMapId]?.autoRefresh ?? extraMap.params.autoRefresh) && extraMap.params.refreshInterval
              ? extraMap.params.refreshInterval
              : false;

            // проверка на необходимость класстеризации
            const onCluster = opacityMap?.onCluster !== undefined ? opacityMap.onCluster : extraMap.params.onCluster !== false;

            handleAbortAndCleanup(extraMapId, controllersRef, moveEndListenersRef); // удалению moveEnd для класстеров и остановки прошлых запросов
            controllersRef.current[extraMapId] = new AbortController();

            handleFetchGeoJson(
              map,
              url,
              autoRefresh,
              extraMapId,
              extraMap.name,
              controllersRef.current[extraMapId].signal,
              decrementGeoJsonCount,
              incrementGeoJsonCount,
              dispatch,
              onCluster,
              moveEndListenersRef,
              updateLoadedMaps,
            );
          }
        } else if (controllersRef.current[extraMapId]) {
          controllersRef.current[extraMapId].abort();
        }

        const baseId = `extraMap-Geojson-${extraMapId}`;
        const opacityValue = opacityMap?.opacity ?? 1;

        // прозрачность
        const opacityPaint = ['case', ['has', 'opacity'], ['*', ['get', 'opacity'], opacityValue], opacityValue];
        const polygonPaint = ['case', ['has', 'opacity'], ['*', ['get', 'opacity'], opacityValue], opacityValue * 0.5];

        setLayerProperties(map, opacityMap?.s, `${baseId}-clusters`, 'circle-opacity', opacityPaint);
        setLayerProperties(map, opacityMap?.s, `${baseId}-cluster-count`, 'text-opacity', opacityPaint);

        setLayerProperties(map, opacityMap?.s, `${baseId}-line`, 'line-opacity', opacityPaint);
        setLayerProperties(map, opacityMap?.s, `${baseId}-line-dashed`, 'line-opacity', opacityPaint);

        setLayerProperties(map, opacityMap?.s, `${baseId}-polygon`, 'fill-opacity', polygonPaint);
        setLayerProperties(map, opacityMap?.s, `${baseId}-polygon3D`, 'fill-extrusion-opacity', opacityValue * 0.5);

        // цвет по умолчанию
        const colorValue = opacityMap?.color ?? extraMap.params.color ?? theme.palette.clusters.red;
        const colorExpression = ['coalesce', ['get', 'color'], colorValue];
        setLayerProperties(map, opacityMap?.s, `${baseId}-clusters`, 'circle-color', [
          'step',
          ['get', 'point_count'],
          `${lightenColor(colorValue, 40)}`,
          10,
          `${lightenColor(colorValue, 20)}`,
          100,
          `${colorValue}`,
          1000,
          `${darkenColor(colorValue, 20)}`,
          10000,
          `${darkenColor(colorValue, 40)}`,
        ]);
        setLayerProperties(map, opacityMap?.s, `${baseId}-circle`, 'circle-color', colorExpression);
        setLayerProperties(map, opacityMap?.s, `${baseId}-line`, 'line-color', colorExpression);
        setLayerProperties(map, opacityMap?.s, `${baseId}-line-dashed`, 'line-color', colorExpression);
        setLayerProperties(map, opacityMap?.s, `${baseId}-polygon`, 'fill-color', colorExpression);
        setLayerProperties(map, opacityMap?.s, `${baseId}-polygon3D`, 'fill-extrusion-color', colorExpression);

        // тепловая карта
        const intensityValue = opacityMap?.intensity ?? 1;
        const radiusValue = opacityMap?.radius ?? 20;
        if (opacityMap?.heatMap ?? extraMap.params?.heatMap) {
          setLayerProperties(map, opacityMap?.s, `${baseId}-heatMap`, 'heatmap-opacity', opacityValue * 0.6);
          setLayerProperties(map, opacityMap?.s, `${baseId}-heatMap`, 'heatmap-intensity', intensityValue);
          setLayerProperties(map, opacityMap?.s, `${baseId}-heatMap`, 'heatmap-radius', radiusValue);

          setLayerProperties(map, false, `${baseId}-circle`, 'circle-opacity', opacityPaint);
          setLayerProperties(map, false, `${baseId}-circle`, 'circle-stroke-opacity', opacityPaint);
          setLayerProperties(map, false, `${baseId}-text`, 'text-opacity', opacityPaint);
        } else {
          setLayerProperties(map, false, `${baseId}-heatMap`, 'heatmap-opacity', opacityValue * 0.6);
          setLayerProperties(map, false, `${baseId}-heatMap`, 'heatmap-intensity', intensityValue);
          setLayerProperties(map, false, `${baseId}-heatMap`, 'heatmap-radius', radiusValue);

          setLayerProperties(map, opacityMap?.s, `${baseId}-circle`, 'circle-opacity', opacityPaint);
          setLayerProperties(map, opacityMap?.s, `${baseId}-circle`, 'circle-stroke-opacity', opacityPaint);
          setLayerProperties(map, opacityMap?.s, `${baseId}-text`, 'text-opacity', opacityPaint);
        }
      } else {
        setLayerProperties(
          map,
          opacityMap?.s,
          extraMapId,
          'raster-opacity',
          opacityMap?.opacity ?? 0,
        );
      }
    });
  }, [opacityMaps]);

  return null;
};

export default ExtraMap;

import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../LocalizationProvider';
import columnTemplates from '../../../columnTemplates';

export default (devicesObject, reportName, coordinateFormat, speedUnit, distanceUnit, users) => {
  const t = useTranslation();
  const theme = useTheme();

  const groups = useSelector((state) => state.groups.items);
  const positions = useSelector((state) => state.positions.items);
  const sources = useSelector((state) => state.devices.sources);
  const devices = useSelector((state) => state.devices.items);
  const geofences = useSelector((state) => state.geofences.items);

  const reportNames = {
    route: 'routeReport',
    radar: 'radarReport',
    anti_uav: 'antiUavReport',
    detected_object: 'detectedObjectsReport',
    trip: 'tripReport',
    stop: 'stopReport',
    event: 'eventReport',
    sms: 'smsReport',
    log: 'logReport',
  };

  return columnTemplates.reportFields[reportNames[reportName]]({
    devicesObject, groups, t, theme, sources, positions, devices, coordinateFormat, speedUnit, distanceUnit, geofences, users,
  });
};

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, Select, Typography, useTheme,
} from '@material-ui/core';
import { Column } from 'devextreme-react/data-grid';
import PageLayout from '../../../common/components/PageLayout';
import useReportStyles from '../hooks/useReportStyles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SuperMenuItem from '../../../common/components/SuperMenuItem';
import ReportSmartFilter from './ReportSmartFilter';
import ReportsMenu from './ReportsMenu';
import SmartTable from '../../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../../common/settings/settingsPage';

const ReportTemplate = ({
  items,
  progress,
  progressDevices,
  setSelectedItems,
  handleDataSelect,
  columnsArray,
  handleSubmit,
  formatValue,
  page,
  typeReport,
  offColumns,
  setOffColumns,
  setPositionsPath,
  progressReport,
  chooseEvents,
  eventTypes,
  events,
  breadcrumbs,
  map,
  withoutDevices,
  devicesObject,
  devicesList,
  countProgress,
  setPeriodChosen,
  periodChosen,
  stylesRow,
  reportColumns,
  enableRepaintChangesOnly,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const classes = useReportStyles();
  const pageEndRef = useRef(null);
  const lastSelectPeriod = JSON.parse(localStorage.getItem('lastSelectPeriod') ?? '{}')[typeReport];
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const scrollToBottom = () => pageEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    scrollToBottom();
  }, [page]);

  useEffect(() => {
    if (formatValue) {
      const result = items.map((item) => {
        const newItem = {};
        columnsArray.forEach((key) => {
          newItem[key[0]] = formatValue(item, key[0], devicesObject);
        });
        newItem.deviceIntId = item.deviceId;
        newItem.course = item.course;
        return newItem;
      });
      setDataSource(result);
    } else {
      setDataSource(items);
    }
  }, [items, formatValue, columnsArray, devicesObject]);

  const showLoadingForm = () => setIsLoading(true);
  const hideLoadingForm = () => setIsLoading(false);

  const handleSubmitWithWrap = (deviceIds, from, to, accept) => {
    if (isLoading) return;
    showLoadingForm();
    handleSubmit(deviceIds, from, to, accept, hideLoadingForm);
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      <div className={classes.container}>
        {map}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportSmartFilter
              progress={progress}
              progressDevices={progressDevices}
              handleSubmit={handleSubmitWithWrap}
              columns={columnsArray}
              typeReport={typeReport}
              off={offColumns}
              setOff={setOffColumns}
              setSelectedItems={setSelectedItems}
              setPositionsPath={setPositionsPath}
              withoutDevices={withoutDevices}
              devicesList={devicesList}
              countProgress={countProgress}
              periodChosen={periodChosen}
              setPeriodChosen={setPeriodChosen}
              showOnly
              lastSelectPeriod={lastSelectPeriod}
            >
              {events && (
                <div className={classes.filterItem}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel>{t('reportEventTypes')}</InputLabel>
                    <Select
                      value={eventTypes}
                      onChange={(e) => chooseEvents(e.target.value)}
                      multiple
                      renderValue={(selected) => {
                        const eventsSelected = selected.map((selectedItem) => t(events[selectedItem]));
                        return eventsSelected.join(', ');
                      }}
                      MenuProps={theme.overrides.MenuProps}
                    >
                      <MenuItem value="all">
                        <SuperMenuItem all={eventTypes.length} />
                      </MenuItem>
                      {Object.keys(events).map((key) => (
                        <MenuItem value={key} style={{ padding: theme.spacing(0, 1) }} key={key}>
                          <Checkbox checked={eventTypes.includes(key)} />
                          <ListItemText primary={t(events[key])} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </ReportSmartFilter>
          </div>

          {isLoading || progress ? (
            <>
              <LinearProgress />
              {progressReport === 0 ? (
                <Typography>{t('sharedLoading')}</Typography>
              ) : (
                <Typography>{`${t('sharedFoundObjects')}: ${progressReport} ${t('sharedNumbers')}`}</Typography>
              )}
            </>
          ) : (
            <SmartTable
              enableRepaintChangesOnly={enableRepaintChangesOnly}
              tableName={typeReport}
              fileExportingName={typeReport}
              allowedPageSizes={allowedPageSizes}
              currentPageSize={currentPageSize}
              arrayDependencies={[items]}
              dataSource={dataSource}
              classes={classes}
              handleDataSelect={handleDataSelect}
              stylesRow={stylesRow}
            >
              {reportColumns
                ? reportColumns()
                : columnsArray.map((column) => (
                  <Column key={column[0]} dataField={column[0]} caption={t(column[1])} />
                ))}
            </SmartTable>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default ReportTemplate;

ReportTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  progress: PropTypes.number.isRequired,
  columnsArray: PropTypes.arrayOf(PropTypes.any),
  handleSubmit: PropTypes.func.isRequired,
  formatValue: PropTypes.func,
  page: PropTypes.number,
  typeReport: PropTypes.string.isRequired,
  offColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOffColumns: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedItems: PropTypes.func,
  handleDataSelect: PropTypes.func,
  setPositionsPath: PropTypes.func,
  chooseEvents: PropTypes.func,
  eventTypes: PropTypes.array,
  events: PropTypes.objectOf(PropTypes.string),
  map: PropTypes.element,
  withoutDevices: PropTypes.bool,
  devicesObject: PropTypes.object,
  devicesList: PropTypes.array,
  countProgress: PropTypes.number,
  setPeriodChosen: PropTypes.func,
  periodChosen: PropTypes.bool,
  stylesRow: PropTypes.func,
  reportColumns: PropTypes.func,
  enableRepaintChangesOnly: PropTypes.bool,
};

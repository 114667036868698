/* eslint-disable no-await-in-loop */
import 'react-calendar-timeline/lib/Timeline.css';
import { useRef } from 'react';
import moment from 'moment';
import { getImgUrl, getVideoUrl } from '../utils/exportHelper';

const VideoCameraExportLayer = ({
  videoCameraLayer = undefined,
}) => {
  const exportedVideos = useRef([]);

  const getExportedVideos = () => [...exportedVideos.current];

  const clearResources = () => {
    exportedVideos.current = [];
  };

  const loadVideosExports = async () => {
    clearResources();

    const videoRecordServices = videoCameraLayer.getVideoRecordServices();

    if (videoRecordServices.size === 0) {
      return;
    }

    const videoCameraDisplayName = {};
    for (const item of Object.values(videoCameraLayer.getVideoCamerasMetadata())) {
      videoCameraDisplayName[item.videoCameraName] = item.nameDisplayed;
    }

    const filteredStrings = [];
    const exportsIds = new Set();

    for (const videoRecordService of videoRecordServices.values()) {
      try {
        const response = await fetch(`${videoRecordService}/api/exports`);
        const exportedVideos = (await response.json()).map((v) => {
          v.nameDisplayed = videoCameraDisplayName[v.camera];

          if (v.name) {
            // Разделяем содержимое строки для получения диапазона даты и времени.
            // Пример: TestNewVCam2 (z1) 31.01.2025 17:00:00 - 31.01.2025 17:59:59
            // Результат: ['TestNewVCam2', '(z1)', '31.01.2025', '17:00:00', '-', '31.01.2025', '17:59:59']
            const fragments = v.name.split(' ');
            // 31.01.2025 17:00:00
            v.startTime = moment(`${fragments[2]} ${fragments[3]}`, 'DD.MM.YYYY HH:mm:ss').unix();
            // 31.01.2025 17:59:59
            v.endTime = moment(`${fragments[5]} ${fragments[6]}`, 'DD.MM.YYYY HH:mm:ss').unix();
          }

          v.videoRecordService = videoRecordService;
          v.videoUrl = getVideoUrl(v);
          v.imgUrl = getImgUrl(v);
          return v;
        });

        if (!Array.isArray(exportedVideos)) {
          continue;
        }

        const allowedVideoCameraNames = videoCameraLayer.getAllowedVideoCameraNames();
        // const now = moment();
        for (const video of exportedVideos) {
          // Исключает видео, которые не удалось обработать за сутки
          // if (video.in_progress) {
          //   const diffInDays = now.diff(moment.unix(video.date), 'days');
          //   if (diffInDays >= 1) {
          //     continue;
          //   }
          // }
          if (exportsIds.has(video.id)) {
            continue;
          }
          exportsIds.add(video.id);

          if (allowedVideoCameraNames.some((allowedName) => video.name.includes(allowedName))) {
            filteredStrings.push(video);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (filteredStrings.length === 0) {
      return;
    }

    filteredStrings.sort((a, b) => b.date - a.date);
    exportedVideos.current = filteredStrings;
  };

  return {
    loadVideosExports, getExportedVideos,
  };
};

export default VideoCameraExportLayer;

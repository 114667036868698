import React, { memo } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import { makeStyles } from '@material-ui/core';
import VideoPlayer from '../../../common/components/VideoPlayer';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  banner: {
    // aspectRatio: '16/ 9',
    width: '100%',
    height: '100%',
    backgroundColor: '#1d1a20',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  label: {
    position: 'absolute',
    top: 1,
    right: 3,
    textShadow: '4px 4px 6px rgba(0, 0, 0, 1)',
    color: 'white',
    fontSize: 10,
    zIndex: 1,
  },
}));

const VideoCameraVideoPlayer = ({
  videoData,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: false,
    muted: true,
    playbackRates: [0.25, 0.5, 1, 1.5, 2, 5, 15],
    controlBar: {
      skipButtons: {
        backward: 10,
        forward: 10,
      },
    },
    sources: [{
      src: videoData?.url,
      type: 'application/x-mpegURL',
    }],
  };

  return (
    <>
      {videoData && videoData.url ? (
        <div className={classes.container}>
          <div className={classes.label}>
            {videoData.label}
          </div>
          <VideoPlayer options={videoJsOptions} />
        </div>
      ) : (
        <div className={classes.banner}>
          {t('globalSelectFragment')}
        </div>
      )}
    </>
  );
};

export default memo(VideoCameraVideoPlayer);

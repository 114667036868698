import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { useTranslation } from '../../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  container: {
    padding: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  confirmWindowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
}));

const ConfirmWindow = ({
  timeline, selectedVideoCameras, formatDate,
  videoCamerasMetadata, isOpenConfirmWindow, closeConfirmWindow, acceptConfirmWindow,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <Dialog
      open={isOpenConfirmWindow}
      onClose={closeConfirmWindow}
    >
      <DialogTitle>{t('globalSavingConfirmation')}</DialogTitle>
      <DialogContent>
        <div className={classes.confirmWindowContainer}>
          <DialogContentText style={{ maxWidth: 365 }}>
            {`${t('globalDataProcessingWarning')}!`}
          </DialogContentText>

          <div>
            <Typography variant="h6">
              {t('reportPeriod')}
              :
            </Typography>
            <Typography variant="subtitle1">
              {`${formatDate(timeline?.from ?? moment())} - ${formatDate(timeline?.to ?? moment())}`}
            </Typography>
          </div>

          <div>
            <Typography variant="h6">
              {t('globalVideoCameras')}
              :
            </Typography>
            <Typography variant="subtitle1">
              {selectedVideoCameras.map((name) => (<div key={name}>{videoCamerasMetadata[name].nameDisplayed}</div>))}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeConfirmWindow} color="primary">
          {t('sharedCancel')}
        </Button>
        <Button onClick={acceptConfirmWindow} color="primary">
          {t('sharedSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmWindow;

/* eslint-disable no-await-in-loop */
import React, { useState, useCallback } from 'react';
import {
  Button, ButtonGroup, Snackbar, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { Save, Visibility } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { CATEGORY_ID } from '../constants';
import DatetimeCombobox, { periodRangeDisplayedEnum } from '../../../../common/components/DatetimeCombobox';
import TreeViewCombobox from '../../../../common/components/TreeViewCombobox';
import ConfirmWindow from './ConfirmWindow';
import { createVideoCameraNameWithTime, formatVideoCameraDate } from '../../utils/videoCameraHelper';
import { isUndefinedOrNull } from '../../../../common/utils/stringUtils';

const useStyles = makeStyles(() => ({
  container: {
    padding: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
}));

export const panelModeEnum = Object.freeze({
  Watch: 'watch',
  Save: 'save',
});

const periodRangeDisplayed = [
  periodRangeDisplayedEnum.Hours,
  periodRangeDisplayedEnum.Weeks,
  periodRangeDisplayedEnum.Days,
  periodRangeDisplayedEnum.Months,
];

const delayTime = 1500;
const fetchDelayTime = 150;

const ControlsPanel = ({
  datetimeFilter, setDatetimeFilter,
  isLoading, reloadVideoCamerasTimeRecords, selectedVideoCameras, setSelectedVideoCameras,
  videoCamerasMetadata, videoCamerasIds,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  // const [panelMode, setPanelMode] = useState(panelModeEnum.Watch);
  const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false);
  const [open, setOpen] = useState({ open: false, message: '' });

  // const isWatchMode = panelMode === panelModeEnum.Watch;
  const isDisabled = isLoading || selectedVideoCameras.length === 0 || isUndefinedOrNull(datetimeFilter?.from) || isUndefinedOrNull(datetimeFilter?.to);

  const createCameras = useCallback(() => {
    const cameras = [];
    for (const cameraId of videoCamerasIds) {
      const { id, nameDisplayed } = videoCamerasMetadata[cameraId];
      cameras.push({ name: nameDisplayed, ID: id, selected: selectedVideoCameras.includes(id) });
    }
    return cameras;
  }, [videoCamerasIds, selectedVideoCameras]);

  const openNotificationWindow = (message, type) => {
    setOpen({ message, type, open: true });
  };

  const closeNotificationWindow = () => {
    setOpen({ ...open, open: false });
  };

  const savePeriodForVideoCameras = async () => {
    if (selectedVideoCameras.length === 0) {
      return;
    }

    let displayDelay = 0;

    for (const videoCameraId of selectedVideoCameras) {
      const { nameDisplayed, videoCameraName, videoRecordService } = videoCamerasMetadata[videoCameraId];

      try {
        const url = `${videoRecordService}/api/export/${videoCameraName}/start/${datetimeFilter.from.unix()}/end/${datetimeFilter.to.unix()}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: createVideoCameraNameWithTime(nameDisplayed, datetimeFilter.from, datetimeFilter.to),
            playback: 'realtime',
          }),
        });

        if (response.ok) {
          setTimeout(() => openNotificationWindow(`${nameDisplayed}. ${t('globalSavedRecord')}.`, 'success'), displayDelay);
        } else if (response.status === 400) {
          setTimeout(() => openNotificationWindow(`${nameDisplayed}. ${t('globalSpecifiedPeriodIsUnavailable')}.`, 'warning'), displayDelay);
        } else if (response.status === 404) {
          setTimeout(() => openNotificationWindow(`${nameDisplayed}. ${t('globalVideoCameraNotFound')}.`, 'warning'), displayDelay);
        } else {
          setTimeout(() => openNotificationWindow(`${nameDisplayed}. ${t('globalRecordCouldNotBeSaved')}.`, 'warning'), displayDelay);
        }
      } catch (error) {
        console.error(error);
        setTimeout(() => openNotificationWindow(`${nameDisplayed}. ${t('globalRecordCouldNotBeSaved')}.`, 'error'), displayDelay);
      } finally {
        displayDelay += delayTime + fetchDelayTime;
      }
    }
  };

  const treeViewItemSelectionChanged = (items) => {
    setSelectedVideoCameras(items.filter((v) => v !== CATEGORY_ID));
  };

  // const buttonText = () => {
  //   if (isWatchMode) {
  //     return t(isLoading ? 'sharedLoading' : 'reportShow');
  //   }
  //   return t(isLoading ? 'sharedLoading' : 'sharedSave');
  // };

  // const handleChangePanelMode = (name) => {
  //   setPanelMode(name);
  // };

  // const getButtonVarian = (mode) => (panelMode === mode ? 'outlined' : 'contained');

  const openConfirmWindow = () => {
    setIsOpenConfirmWindow(true);
  };

  const closeConfirmWindow = () => {
    setIsOpenConfirmWindow(false);
  };

  const acceptConfirmWindow = () => {
    savePeriodForVideoCameras();
    closeConfirmWindow();
  };

  return (
    <div className={classes.container}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open.open}
        onClose={closeNotificationWindow}
        autoHideDuration={delayTime}
      >
        <Alert severity={open.type}>
          {open.message}
        </Alert>
      </Snackbar>

      {/* <ButtonGroup size="small" color="default" fullWidth disableElevation>
        <Button
          name={panelModeEnum.Watch}
          startIcon={<Visibility />}
          variant={getButtonVarian(panelModeEnum.Watch)}
          onClick={() => handleChangePanelMode(panelModeEnum.Watch)}
        >
          {t('globalWatch')}
        </Button>
        <Button
          startIcon={<Save />}
          variant={getButtonVarian(panelModeEnum.Save)}
          onClick={() => handleChangePanelMode(panelModeEnum.Save)}
        >
          {t('sharedSave')}
        </Button>
      </ButtonGroup> */}

      <DatetimeCombobox
        column
        variant="outlined"
        disabled={isLoading}
        periodRangeDisplayed={periodRangeDisplayed}
        datetime={datetimeFilter}
        setDatetime={setDatetimeFilter}
      />

      <TreeViewCombobox
        disabled={isLoading}
        value={selectedVideoCameras}
        displayExpr="name"
        keyExpr="ID"
        valueExpr="ID"
        parentIdExpr="categoryId"
        label={t('globalVideoCameras')}
        datasetCallback={createCameras}
        onSelectionChangedKeys={treeViewItemSelectionChanged}
      />

      <ConfirmWindow
        formatDate={formatVideoCameraDate}
        acceptConfirmWindow={acceptConfirmWindow}
        videoCamerasMetadata={videoCamerasMetadata}
        closeConfirmWindow={closeConfirmWindow}
        isOpenConfirmWindow={isOpenConfirmWindow}
        selectedVideoCameras={selectedVideoCameras}
        timeline={datetimeFilter}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={reloadVideoCamerasTimeRecords}
      >
        {t(isLoading ? 'sharedLoading' : 'reportShow')}
      </Button>

      <Button
        variant="outlined"
        color="primary"
        disabled={isDisabled}
        onClick={openConfirmWindow}
      >
        {t(isLoading ? 'sharedLoading' : 'sharedSave')}
      </Button>
    </div>
  );
};

export default ControlsPanel;

import React, { useEffect } from 'react';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar, Paper, Tooltip, makeStyles,
} from '@material-ui/core';
import { positionsActions } from '../../store';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
  },
  eyeInactive: {
    color: '#707070',
  },
  eyeActive: {
    color: '#E32636',
  },
  '@keyframes color': {
    '50%': {
      color: '#ebebeb',
    },
  },
  trackingModeOn: {
    animation: '$fadeIn .2s ease-in-out',
    position: 'fixed',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    maxWidth: 220,
    zIndex: 1310,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  trackingModeOff: {
    animation: '$fadeOut 2.0s ease-in-out',
    position: 'fixed',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    maxWidth: 220,
    zIndex: 1310,
    backgroundColor: 'rgba(255,255,255,0.8)',
    opacity: 0,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  textTrackingMode: {
    margin: theme.spacing(2),
    color: '#f55656',
    fontWeight: 'bold',
  },
}));
const TrackingModeOn = ({ deviceSelected }) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const trackingMode = useSelector((state) => state.positions.trackingMode);
  const trackingModeOnSelect = useSelector((state) => state.session?.user?.attributes?.trackingModeOnSelect);

  useEffect(() => {
    dispatch(positionsActions.changeTrackingMode(trackingModeOnSelect));
  }, [deviceSelected]);

  return (
    <>
      <Paper className={classes.trackingMode} elevation={3}>
        <Tooltip title={t('trackingMode')}>
          <Avatar
            onClick={() => { dispatch(positionsActions.changeTrackingMode(!trackingMode)); }}
            className={classes.avatar}
          >
            <RemoveRedEyeIcon className={`${trackingMode ? classes.eyeActive : classes.eyeInactive}`} />
          </Avatar>
        </Tooltip>
      </Paper>
    </>
  );
};

export default TrackingModeOn;

/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
import JSONStream from 'JSONStream';
import Supercluster from 'supercluster';
import { errorsActions, mapActions } from '../../store';

function getBbox(map) {
  const bounds = map.getBounds();
  const southWest = bounds.getSouthWest();
  const northEast = bounds.getNorthEast();

  const latDifference = (northEast.lat - southWest.lat) * 0.1;
  const lngDifference = (northEast.lng - southWest.lng) * 0.1;

  return [
    southWest.lng - lngDifference,
    southWest.lat - latDifference,
    northEast.lng + lngDifference,
    northEast.lat + latDifference,
  ];
}

async function processStream(reader, stream, signal) {
  const decoder = new TextDecoder('utf-8');

  signal.addEventListener('abort', () => {
    reader.cancel();
    stream.end();
  });

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      stream.end();
      break;
    }

    const chunk = decoder.decode(value, { stream: true });
    stream.write(chunk);
    await new Promise((resolve) => requestIdleCallback(resolve));
  }
}

async function fetchGeoJsonData(url, name, signal, decrementGeoJsonCount, dispatch) {
  try {
    const response = await fetch(url, { signal });
    if (!response.body) {
      console.error('ReadableStream not supported in this browser.');
      return null;
    }

    const reader = response.body.getReader();
    const stream = JSONStream.parse('features.*');
    const featureArr = [];

    stream.on('data', (geoJsonObject) => {
      featureArr.push(geoJsonObject);
    });

    await new Promise((resolve, reject) => {
      stream.on('end', resolve);
      stream.on('error', reject);
      processStream(reader, stream, signal);
    });

    if (signal.aborted) {
      return [];
    }
    return featureArr;
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error('Error during fetching data:', error);
      dispatch(errorsActions.push(`Не удалось загрузить доп. слой: ${name}`));
    }
    decrementGeoJsonCount();
    return null;
  }
}

export default async function fetchWithCluster(map, url, autoRefresh, id, name, signal, decrementGeoJsonCount, incrementGeoJsonCount, updateLoadedMaps, dispatch) {
  const index = new Supercluster({
    radius: 20,
    maxZoom: 14,
    minZoom: 0,
  });

  const updateClusters = () => {
    try {
      const newClusters = index.getClusters(getBbox(map), map.getZoom());
      if (map.getSource(id)) {
        map.getSource(id).setData({
          type: 'FeatureCollection',
          features: newClusters,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function loadAndUpdateData() {
    incrementGeoJsonCount();

    const featureArr = await fetchGeoJsonData(url, name, signal, decrementGeoJsonCount, dispatch);
    if (!featureArr) {
      return;
    }
    index.load(featureArr);
    dispatch(mapActions.updateFeatureCount({ id, count: featureArr.length }));

    updateClusters();
    decrementGeoJsonCount();

    if (autoRefresh) {
      setTimeout(async () => {
        await loadAndUpdateData();
      }, autoRefresh * 60 * 1000);
    }
    return (!signal.aborted);
  }

  try {
    const isSuccess = await loadAndUpdateData();
    if (isSuccess) {
      updateLoadedMaps();
      map.on('moveend', updateClusters);

      return () => {
        map.off('moveend', updateClusters);
      };
    }
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error(error);
    }
  }
}

/* eslint-disable no-await-in-loop */
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { useRef } from 'react';
import {
  createVideoUrlForM3U8, formatVideoCameraDatetime,
} from '../utils/videoCameraHelper';
import { getThumbUrl } from '../utils/reviewHelper';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const getUrl = (videoRecordService, params) => {
  let url = `${videoRecordService}/api/review`;
  const query = new URLSearchParams();

  if (!isUndefinedOrNull(params.eventReviewLimit)) {
    query.append('limit', params.eventReviewLimit);
  }
  if (!isUndefinedOrNull(params.beforeDateInUnix)) {
    query.append('before', params.beforeDateInUnix);
  }
  if (!isUndefinedOrNull(params.afterDateInUnix)) {
    query.append('after', params.afterDateInUnix);
  }
  if (!isUndefinedOrNull(params.camerasArray) && params.camerasArray.length > 0) {
    query.append('cameras', [...new Set(params.camerasArray)].join(','));
  }

  if ([query.values()].length > 0) {
    url = `${videoRecordService}/api/review?${query.toString()}`;
  }

  return url;
};

const SECONDS = 20;

const VideoCameraEventReviewLayer = ({
  videoCameraLayer = undefined,
}) => {
  const eventReviews = useRef([]);

  const getEventReviews = () => [...eventReviews.current];

  const clearResources = () => {
    eventReviews.current = [];
  };

  const addPeriodTimeIfRequired = (eventReviewItem) => {
    if (isUndefinedOrNull(eventReviewItem.start_time) && isUndefinedOrNull(eventReviewItem.end_time)) {
      eventReviewItem.start_time = moment().unix();
      eventReviewItem.end_time = moment().unix() + SECONDS;
    } else if (isUndefinedOrNull(eventReviewItem.start_time)) {
      eventReviewItem.start_time = eventReviewItem.end_time - SECONDS;
    } else if (isUndefinedOrNull(eventReviewItem.end_time)) {
      eventReviewItem.end_time = eventReviewItem.start_time + SECONDS;
    }
  };

  const loadEventReviews = async ({
    eventReviewLimit = undefined, beforeDateInUnix = undefined, afterDateInUnix = undefined,
    camerasArray = undefined,
  }) => {
    clearResources();

    const videoRecordServices = videoCameraLayer.getVideoRecordServices();

    if (videoRecordServices.size === 0) {
      return;
    }

    const videoCameraDisplayName = {};
    for (const item of Object.values(videoCameraLayer.getVideoCamerasMetadata())) {
      videoCameraDisplayName[item.videoCameraName] = item.nameDisplayed;
    }

    const filteredStrings = [];
    const reviewsIds = new Set();

    for (const videoRecordService of videoRecordServices.values()) {
      try {
        const url = getUrl(
          videoRecordService,
          {
            eventReviewLimit,
            beforeDateInUnix,
            afterDateInUnix,
            camerasArray,
          },
        );

        const response = await fetch(url);
        const reviews = (await response.json()).map((rev) => {
          rev.videoRecordService = videoRecordService;
          rev.thumbUrl = getThumbUrl(rev);

          addPeriodTimeIfRequired(rev);

          rev.nameDisplayed = videoCameraDisplayName[rev.camera];

          rev.datetimeText = formatVideoCameraDatetime(moment.unix(rev.start_time));
          rev.text = `${rev.nameDisplayed} ${rev.datetimeText}`;
          rev.videoUrl = createVideoUrlForM3U8(rev);

          return rev;
        });

        if (!Array.isArray(reviews)) {
          continue;
        }

        const allowedVideoCameraNames = videoCameraLayer.getAllowedVideoCameraNames();
        for (const review of reviews) {
          if (reviewsIds.has(review.id)) {
            continue;
          }
          reviewsIds.add(review.id);

          if (allowedVideoCameraNames.some((allowedName) => allowedName === review.nameDisplayed)) {
            filteredStrings.push(review);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (filteredStrings.length === 0) {
      return;
    }

    filteredStrings.sort((a, b) => b.start_time - a.start_time);
    eventReviews.current = filteredStrings;
  };

  return {
    loadEventReviews, getEventReviews,
  };
};

export default VideoCameraEventReviewLayer;

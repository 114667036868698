import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'dataPanel',
  initialState: {
    isOpenAttachedFiles: false,
    isOpenChoice: false,
    isOpenEditForm: false,
    isOpenExtraSettings: false,
  },
  reducers: {
    openTab(state, action) {
      state.isOpenAttachedFiles = false;
      state.isOpenChoice = false;
      state.isOpenEditForm = false;
      state.isOpenExtraSettings = false;
      state[action.payload] = true;
    },
    switchTab(state, action) {
      const isOpen = state[action.payload]
      state.isOpenAttachedFiles = false;
      state.isOpenChoice = false;
      state.isOpenEditForm = false;
      state.isOpenExtraSettings = false;
      state[action.payload] = !isOpen;
    },
    closeTabs(state) {
      state.isOpenAttachedFiles = false;
      state.isOpenChoice = false;
      state.isOpenEditForm = false;
      state.isOpenExtraSettings = false;
    },
    closeTab(state, action) {
      state[action.payload] = false;
    },
  },
});

export { actions as dataPanelActions };
export { reducer as dataPanelReducer };

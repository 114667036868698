import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import logout from '../../common/utils/logout';
import { getIsAdmin } from '../../common/utils/selectors';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { errorsActions, smsActions } from '../../store';
import useTemplatesFieldsPresetForDevicesSettings from '../../common/components/SmartTable/hooks/columnTemplates/settingsTables/useTemplatesFieldsPresetForDevicesSettings';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';
import SmsChat from '../../common/components/SmsChat';

const DevicesView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = useSelector(getIsAdmin);
  const [items, setItems] = useState([]);

  const [requestProgress, setRequestProgress] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const apiCall = async () => {
      try {
        const query = new URLSearchParams({});
        query.append('excludeDetected', true);
        const response = await fetch(`/api/devices${`?${query.toString()}${isAdmin ? '&all=true' : ''}`}`, { signal });
        if (response.ok) {
          setItems(await response.json());
        } else if (response.status === 401) {
          if (response.status === 401) {
            logout(history, dispatch);
          }
        }
        setRequestProgress(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        }
      }
    };
    apiCall();
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    };
  }, [updateTimestamp]);

  const templateField = useTemplatesFieldsPresetForDevicesSettings();
  const completedColumns = useAutoCompleteColumns(templateField);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="DevicesPage"
      fileExportingName="serverPageDevice"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
      disableAddNewRow={!isAdmin}
    >
      {completedColumns()}
    </SmartTable>
  );
};

DevicesView.propTypes = {
  updateTimestamp: PropTypes.number.isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

const DevicesPage = () => {
  const smsChats = useSelector((state) => state.sms.smsChats);
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(smsActions.clearSmsChat());
  }, []);

  return (
    <>
      <OptionsLayout>
        <EditCollectionView
          hideButtonAdd
          content={DevicesView}
          editPath="/device"
          endpoint="devices"
        />
      </OptionsLayout>
      {smsChats.length > 0
        && smsChats.map((deviceId) => <SmsChat deviceId={deviceId} />)}
    </>
  );
};

export default DevicesPage;

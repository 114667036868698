import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Typography,
  Divider,
  Drawer,
  IconButton,
  Hidden,
  Tooltip,
  Button,
  makeStyles,
} from '@material-ui/core';
import SideNav from '../../../common/components/SideNav';
import NavBar from '../../../common/components/NavBar';
import useRoutes from './useRoutes';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import ConfirmCacheClear from '../components/ConfirmCacheClear';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawerContainer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
  },
  content: {
    flex: 1,
    maxWidth: '100%',
    // padding: theme.spacing(0, 3, 3, 3),
    // padding: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      // padding: theme.spacing(6, 1, 3, 1),
      padding: theme.spacing(6, 1, 0, 1),
    },
    overflow: 'auto',
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.toolbar,
    },
  },
}));

const OptionsLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const routes = useRoutes();
  const t = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(history.location.pathname === '/settings');
  const [optionTitle, setOptionTitle] = useState();
  const [openConfirmCacheClear, setOpenConfirmCacheClear] = useState(false);

  const hundleCachButton = () => {
    setOpenConfirmCacheClear(true);
  };

  useEffect(() => {
    const activeRoute = routes.find(
      (route) => route.href && location.pathname.match(route.match || route.href),
    );
    setOptionTitle(activeRoute?.name);
  }, [location, routes]);
  const title = optionTitle ? `${t('settingsTitle')} / ${optionTitle}` : t('settingsTitle');

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <NavBar setOpenDrawer={setOpenDrawer} title={title} />
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={() => setOpenDrawer(!openDrawer)}
          classes={{ paper: classes.drawer }}
        >
          <SideNav routes={routes} />
          <Button variant="text" onClick={hundleCachButton}>
            <Typography variant="caption">
              Очистить КЭШ
            </Typography>
          </Button>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          variant="permanent"
          classes={{ root: classes.drawerContainer, paper: classes.drawer }}
        >
          <div className={classes.drawerHeader}>
            <Tooltip title={t('backTitle')}>
              <IconButton onClick={() => history.push('/')}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" color="inherit" noWrap>
              {t('settingsTitle')}
            </Typography>
          </div>
          <Divider />
          <SideNav routes={routes} />
          <Button variant="text" onClick={hundleCachButton}>
            <Typography variant="caption">
              Очистить КЭШ
            </Typography>
          </Button>
        </Drawer>
      </Hidden>

      <div className={classes.content}>{children}</div>
      <ConfirmCacheClear open={openConfirmCacheClear} setOpen={setOpenConfirmCacheClear} />
    </div>
  );
};

export default OptionsLayout;

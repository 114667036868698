import React, { useMemo, useRef, useState } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import DeleteConfirmWindow from './DeleteConfirmWindow';
import VideoPreviewWindow from './VideoPreviewWindow';

const VideoCameraActionsWindows = ({
  onDeleteConfirm,
  type = 'video/mp4',
  additionalOptions = {
    disablePictureInPicture: true,
    responsive: true,
    fluid: false,
    controlBar: {
      skipButtons: {
        backward: 10,
        forward: 10,
      },
    },
  },
}) => {
  const [isOpenDeleteConfirmWindow, setIsOpenDeleteConfirmWindow] = useState(false);
  const [isOpenVideoPreviewWindow, setIsOpenVideoPreviewWindow] = useState(false);

  const videoPreviewData = useRef({});

  const openDeleteConfirmWindow = () => {
    setIsOpenDeleteConfirmWindow(true);
  };

  const closeDeleteConfirmWindow = () => {
    setIsOpenDeleteConfirmWindow(false);
  };

  const acceptDeleteConfirmWindow = async () => {
    if (onDeleteConfirm) {
      onDeleteConfirm(videoPreviewData.current);
    }

    closeDeleteConfirmWindow();
  };

  const setDataForWindows = (item) => {
    videoPreviewData.current = { ...item };
  };

  const openVideoPreviewWindow = () => {
    setIsOpenVideoPreviewWindow(true);
  };

  const closeVideoPreviewWindow = () => {
    setIsOpenVideoPreviewWindow(false);
  };

  const ActionsWindows = (
    <>
      {useMemo(() => (
        <>
          {isOpenDeleteConfirmWindow && (
            <DeleteConfirmWindow
              acceptConfirmWindow={acceptDeleteConfirmWindow}
              closeConfirmWindow={closeDeleteConfirmWindow}
              text={videoPreviewData.current?.text}
            />
          )}

          {isOpenVideoPreviewWindow && (
            <VideoPreviewWindow
              defaultWidth="80%"
              defaultHeight="97.72%"
              type={type}
              additionalOptions={additionalOptions}
              title={videoPreviewData.current?.text}
              url={videoPreviewData.current?.srcUrl}
              closeWindow={closeVideoPreviewWindow}
            />
          )}
        </>
      ), [videoPreviewData.current.text, videoPreviewData.current.srcUrl, isOpenDeleteConfirmWindow, isOpenVideoPreviewWindow])}
    </>
  );

  return {
    setDataForWindows, ActionsWindows, openDeleteConfirmWindow, closeDeleteConfirmWindow, openVideoPreviewWindow, closeVideoPreviewWindow,
  };
};

export default VideoCameraActionsWindows;

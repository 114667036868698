import React, { useRef } from 'react';
import Timeline, {
  DateHeader, SidebarHeader, TimelineHeaders,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import 'moment/locale/ru';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { DELIMITER } from './constants';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const useStyles = makeStyles(() => ({
  itemDefaultColor: {
    background: '#dbdbdb !important',
    border: '1px solid #9e9e9e !important',
    color: 'black !important',
    overflow: 'hidden',
  },
  itemSelectedColor: {
    background: '#4272f3 !important',
    border: '1px solid #1a46bb !important',
    overflow: 'hidden',
  },
}));

const VideoCameraTimeline = ({
  videoCamerasDataset, videoCamerasMetadata, groups, timeline, setVideoData,
}) => {
  const classes = useStyles();

  const selectedInterval = useRef('');

  return (
    <Timeline
      stackItems
      showCursorLine
      // lineHeight={60}
      canMove={false}
      canResize={false}
      items={Object.values(videoCamerasDataset).flat()}
      groups={groups}
      defaultTimeStart={isUndefinedOrNull(timeline?.from) ? moment().toDate() : timeline.from.toDate()}
      defaultTimeEnd={isUndefinedOrNull(timeline?.to) ? moment().toDate() : timeline.to.toDate()}
      itemRenderer={({ item, getItemProps }) => {
        const itemColor = item.id === selectedInterval.current ? classes.itemSelectedColor : classes.itemDefaultColor;
        return (
          <div {...getItemProps()} className={`rct-item ${item.className} ${itemColor}`}>
            {item.title}
          </div>
        );
      }}
      onItemClick={(itemId) => {
        if (videoCamerasDataset.length === 0) {
          return;
        }

        const [cameraId] = itemId.split(DELIMITER);
        const { videoCameraName, videoRecordService } = videoCamerasMetadata[cameraId];
        for (const data of videoCamerasDataset[cameraId]) {
          if (data.id === itemId) {
            const {
              id, startTimeUnix, endTimeUnix, label,
            } = data;

            selectedInterval.current = id;
            setVideoData({
              url: `${videoRecordService}/vod/${videoCameraName}/start/${startTimeUnix}/end/${endTimeUnix}/master.m3u8`,
              // eslint-disable-next-line object-shorthand
              label: label,
            });
            break;
          }
        }
      }}
    >
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => (<div className="custom-date-header" {...getRootProps()} />)}
        </SidebarHeader>

        <DateHeader className="custom-date-header" unit="primaryHeader" />
        <DateHeader />
      </TimelineHeaders>
    </Timeline>
  );
};

export default VideoCameraTimeline;

import React from 'react';
import {
  Button,
  ButtonGroup,
  makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import DatetimeCombobox, { periodRangeDisplayedEnum } from '../../../common/components/DatetimeCombobox';
import TreeViewCombobox from '../../../common/components/TreeViewCombobox';

const useStyles = makeStyles(() => ({
  container: {
    // marginBottom: 10,
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  item: {
    width: '100%',
  },
}));

const periodRangeDisplayed = [
  periodRangeDisplayedEnum.Hours,
  periodRangeDisplayedEnum.Weeks,
  periodRangeDisplayedEnum.Days,
  periodRangeDisplayedEnum.Months,
];

const VideoCamerasFiltersPanel = ({
  datetimeFilter, setDatetimeFilter,
  selectedVideoCameras, createCameras, treeViewItemSelectionChanged,
  onApplyFilter, onResetFilter = undefined, defaultPeriod = undefined,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <DatetimeCombobox
          defaultPeriod={defaultPeriod}
          inLine
          size="small"
          variant="outlined"
          datetime={datetimeFilter}
          setDatetime={setDatetimeFilter}
          periodRangeDisplayed={periodRangeDisplayed}
        />
      </div>

      <div className={classes.item}>
        <TreeViewCombobox
          height={40}
          value={selectedVideoCameras}
          displayExpr="name"
          keyExpr="ID"
          valueExpr="ID"
          parentIdExpr="categoryId"
          label={t('globalVideoCameras')}
          datasetCallback={createCameras}
          onSelectionChangedKeys={treeViewItemSelectionChanged}
        />
      </div>

      <div className={classes.item}>
        <ButtonGroup fullWidth color="primary">
          {onResetFilter && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onResetFilter}
            >
              {t('globalReset')}
            </Button>
          )}
          <Button
            size="small"
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onApplyFilter}
          >
            {t('sharedShow')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default VideoCamerasFiltersPanel;

/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React from 'react';
import { ActionsMenuHeaderCellComponent } from '../../columns/headers';
import {
  ActionsMenuRender, AutoregistrationIconCellRender, ColorConnectionCellRender,
  ColorsCellRender, IconCellRender,
  NameCellRender, NotificatorLinksRender, SenderCellRender, StatusCellRender, TextIconCellRender,
} from '../../columns/cells';
import {
  getCategoryName, getDateTime, getTextForIconOrDefaultValue,
  getTextFromIcon, getTextFromIconWithSeparator, toFixedNumber,
  translateConnectionStatus, translateConnectionStatusFromItem, translateDeviceStatus,
  concateStringsFromNotificator,
  translateDeviceStatusFromItem,
  tryConvertAndFixedNumber,
} from '../../funcs/calculateCellValue';
import {
  ColorsGroupCellRender, StatusGroupCellRender,
  TextIconGroupCellRender,
} from '../../columns/groups';
import { isUndefinedOrNull, prefixString } from '../../../../utils/stringUtils';
import { getStringFromBoolean } from '../../funcs/utils';
import { translateDeviceModel } from '../../funcs/sharedCalculateCellValue';
import { formatBoolean, formatDate, formatDistance, formatGeofence, formatMsToTime, formatPosition, formatSmsType, formatSpeed, getUserType } from '../../../../utils/formatter';

export default {
  calculateValues: {
    action: ({ actionMenu, innerClasses, selectedObjects }) => ({
      name: 'globalActions',
      caption: 'globalActions',
      allowExporting: false,
      allowFiltering: false,
      allowGrouping: false,
      width: 40,
      headerCellComponent: () => <ActionsMenuHeaderCellComponent innerClasses={innerClasses} onMenuClick={actionMenu.openActionMenu} selectedObjects={selectedObjects} />,
      cellComponent: ({ data }) => <ActionsMenuRender dataset={data} innerClasses={innerClasses} onMenuClick={actionMenu.openActionMenu} />,
    }),
    deviceName: {
      withHighlightAndTracking: ({ innerClasses, chosenDevice }) => ({
        name: 'deviceNameWithHighlightAndTracking',
        dataField: 'name',
        caption: 'deviceName',
        cellComponent: ({ data }) => <NameCellRender chosenDevice={chosenDevice} dataset={data} innerClasses={innerClasses} />,
      }),
    },
    deviceStatus: ({ deviceListClasses, t }) => ({
      name: 'deviceStatus',
      dataField: 'status',
      caption: 'deviceStatus',
      width: 27,
      cellComponent: ({ data }) => <StatusCellRender dataset={data} deviceListClasses={deviceListClasses} />,
      groupCellComponent: ({ data }) => <StatusGroupCellRender dataset={data} deviceListClasses={deviceListClasses} />,
      calculateCellValue: (item) => translateDeviceStatusFromItem(item, t),
      calculateGroupValue: (item) => translateDeviceStatus(item, t),
      calculateDisplayValue: (item) => translateDeviceStatusFromItem(item, t),
    }),
    lastUpdateShort: ({ now, t }) => ({
      name: 'lastUpdateShort',
      dataField: 'lastUpdate',
      caption: 'lastUpdateShortTable',
      allowSorting: true,
      allowGrouping: false,
      width: 60,
      calculateCellValue: (item) => `${formatMsToTime(now.valueOf() - new Date(item.lastUpdate).valueOf(), t)}`,
      calculateGroupValue: (item) => `${formatMsToTime(now.valueOf() - new Date(item.lastUpdate).valueOf(), t)}`,
      // cellComponent: ({ data }) => <LastUpdateCellRender dataset={data} now={now} t={t} />,
      // groupCellComponent: ({ data }) => <LastUpdateGroupCellRender dataset={data} now={now} t={t} />,
    }),
    lastUpdate: () => ({
      name: 'lastUpdate',
      dataField: 'lastUpdate',
      dataType: 'datetime',
      caption: 'deviceLastUpdate',
      visible: false,
      // customizeText: (text) => getDateTime(text?.value),
      calculateDisplayValue: (item) => getDateTime(item?.lastUpdate),
    }),
    deviceIcon: {
      icon: ({ t }) => ({
        name: 'deviceIcon',
        dataField: 'icon',
        caption: 'deviceIcon',
        width: 40,
        cellComponent: ({ data }) => <IconCellRender dataset={data} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(item, t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(item, t),
        calculateDisplayValue: (item) => getTextFromIcon(item, t),
      }),
      iconWithText: ({ t }) => ({
        name: 'deviceIconWithText',
        dataField: 'icon',
        caption: 'deviceIcon',
        cellComponent: ({ data }) => <TextIconCellRender dataset={data} t={t} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(item, t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(item, t),
        calculateDisplayValue: (item) => getTextFromIcon(item, t),
      }),
    },
    deviceColor: ({ theme }) => ({
      name: 'attributes.color',
      dataField: 'attributes.color',
      caption: 'sharedColor',
      width: 40,
      cellComponent: ({ data }) => <ColorsCellRender dataset={data} theme={theme} />,
      groupCellComponent: ({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />,
      calculateCellValue: (item) => getTextForIconOrDefaultValue(item, theme),
    }),
    deviceConnectionStatus: {
      text: ({ t }) => ({
        name: 'deviceConnectionStatusText',
        dataField: 'attributes.categoryParams.connectionStatus',
        caption: 'deviceConnectionStatus',
        calculateCellValue: (item) => translateConnectionStatusFromItem(item, t),
      }),
      iconFromPositions: ({ t, positions, theme }) => ({
        name: 'deviceConnectionStatusIconFromPositions',
        dataField: 'attributes.categoryParams.connectionStatus',
        caption: 'deviceConnectionStatus',
        width: 40,
        cellComponent: ({ data }) => <ColorConnectionCellRender dataset={data} positions={positions} theme={theme} />,
        calculateCellValue: (item) => translateConnectionStatus(item, t, positions),
      }),
    },
    category: ({ t }) => ({
      name: 'category',
      dataField: 'category',
      caption: 'deviceCategory',
      calculateCellValue: (item) => getCategoryName(item, t),
    }),
    notificationType: ({ t }) => ({
      name: 'notificationType',
      dataField: 'type',
      caption: 'notificationType',
      customizeText: (text) => t(prefixString('event', text?.value)),
    }),
    isEnableNotificationForAllDevices: ({ t }) => ({
      name: 'isEnableNotificationForAllDevices',
      dataField: 'always',
      dataType: 'string',
      caption: 'notificationAlways',
      calculateCellValue: (item) => getStringFromBoolean(item?.always, t),
    }),
    notificators: ({ t }) => ({
      name: 'notificators',
      dataField: 'notificators',
      caption: 'notificationNotificators',
      cellComponent: ({ data }) => <NotificatorLinksRender dataset={data} t={t} />,
      calculateCellValue: (item) => concateStringsFromNotificator(item, t),
    }),
    accountType: ({ t }) => ({
      name: 'accountType',
      dataField: 'reportAccountType',
      caption: 'reportAccountType',
      calculateCellValue: (item) => getUserType(item, t),
    }),
    isAutoregistration: ({ t, registerUnknownDefaultGroupId }) => ({
      name: 'isAutoregistration',
      caption: 'autoregistrationTitle',
      dataType: 'string',
      allowFiltering: true,
      allowSorting: true,
      allowGrouping: true,
      cellComponent: ({ data }) => <AutoregistrationIconCellRender data={data} registerUnknownDefaultGroupId={registerUnknownDefaultGroupId} />,
      calculateCellValue: (item) => getStringFromBoolean(item?.id === registerUnknownDefaultGroupId, t),
    }),
    deviceModel: ({ t }) => ({
      name: 'deviceModel',
      dataField: 'model',
      caption: 'deviceModel',
      calculateCellValue: (item) => translateDeviceModel(t, item?.model),
    }),
  },

  base: {
    groupName: {
      name: 'groupName',
      dataType: 'string',
      caption: 'deviceGroup',
    },
    sourceId: {
      name: 'sourceId',
      dataType: 'string',
      caption: 'deviceSource',
    },
  },

  common: {
    id: { dataField: 'id', caption: 'deviceIdentifier', visible: false },
    uniqueId: { dataField: 'uniqueId', caption: 'deviceIdentifier', visible: false },
    name: { dataField: 'name', caption: 'reportDevice' },
    userName: { dataField: 'name', caption: 'userName' },
    address: { dataField: 'address', caption: 'reportAddress' },
    remoteAddress: { dataField: 'remoteAddress', caption: 'reportRemoteAddress' },
    protocol: { dataField: 'protocol', caption: 'reportProtocol' },
    altitude: {
      dataField: 'altitude',
      caption: 'globalAltitude',
      dataType: 'number',
      alignment: 'left',
      calculateCellValue: (item) => tryConvertAndFixedNumber(item?.altitude),
    },
    message: {
      name: 'message',
      dataField: 'message',
      caption: 'reportMessage',
      calculateCellValue: (item) => item.attributes.message,
    },
    powerLine: {
      name: 'powerLine',
      dataField: 'powerLine',
      caption: 'reportPowerLine',
      calculateCellValue: (item) => item.attributes.powerLine,
    },
    visibleSat: {
      name: 'sat',
      dataField: 'sat',
      caption: 'reportSat',
      calculateCellValue: (item) => (item.attributes.sat !== 99 ? item.attributes.sat : ''),
    },
    mccMnc: {
      name: 'mccMnc',
      dataField: 'mccMnc',
      caption: 'reportMccMnc',
      calculateCellValue: (item) => item.network?.cellTowers
        .map((tower, index) => (index === 0 && `${tower.mobileCountryCode}-${tower.mobileNetworkCode}`))[0],
    },
    lacCid: {
      name: 'lacCid',
      dataField: 'lacCid',
      caption: 'reportLacCid',
      calculateCellValue: (item) => item.network?.cellTowers.map((tower) => `${tower.locationAreaCode}-${tower.cellId}`)[0],
    },
    visibleCids: {
      name: 'visibleCids',
      dataField: 'visibleCids',
      caption: 'reportVisibleCids',
      calculateCellValue: (item) => {
        try {
          if (isUndefinedOrNull(item.network?.cellTowers)) {
            return '';
          }
          if (item.network?.cellTowers.length > 1) {
            return item.network.cellTowers.slice(1).map((tower) => (tower.cellId)).join(',');
          }
          return item.network.cellTowers[0];
        } catch (error) {
          console.log(error);
          return '';
        }
      },
    },
    startTime: {
      name: 'startTime',
      dataField: 'startTime',
      caption: 'reportStartTime',
      calculateCellValue: (item) => formatDate(item.startTime),
    },
    endTime: {
      name: 'endTime',
      dataField: 'endTime',
      caption: 'reportEndTime',
      calculateCellValue: (item) => formatDate(item.endTime),
    },
    eventTime: {
      name: 'eventTime',
      dataField: 'eventTime',
      caption: 'reportEventDateAndTime',
      calculateCellValue: (item) => formatDate(item.eventTime),
    },
    smsTime: {
      name: 'smsTime',
      dataField: 'smsTime',
      caption: 'reportDateAndTime',
      calculateCellValue: (item) => formatDate(item.smsTime),
    },
    logTime: {
      name: 'logTime',
      dataField: 'logTime',
      caption: 'reportDateAndTime',
      calculateCellValue: (item) => formatDate(item.logTime),
    },
    startAddress: {
      name: 'startAddress',
      dataField: 'startAddress',
      caption: 'reportStartAddress',
      calculateCellValue: (item) => item.startAddress,
    },
    endAddress: {
      name: 'endAddress',
      dataField: 'endAddress',
      caption: 'reportEndAddress',
      calculateCellValue: (item) => item.endAddress,
    },
    senderPhone: {
      name: 'senderPhone',
      dataField: 'senderPhone',
      caption: 'reportSenderPhone',
      calculateCellValue: (item) => (item.type === 'inbox' ? item.senderPhone : ''),
    },
    recipientPhone: {
      name: 'recipientPhone',
      dataField: 'recipientPhone',
      caption: 'reportRecipientPhone',
      calculateCellValue: (item) => (item.type === 'outbox' ? item.recipientPhone : ''),
    },
    smsMessage: {
      name: 'message',
      dataField: 'message',
      caption: 'reportMessage',
      calculateCellValue: (item) => item.message,
    },
    success: {
      name: 'success',
      dataField: 'success',
      caption: 'reportSuccess',
      calculateCellValue: (item) => (item.success ? '✅' : '❌'),
    },
    accuracy: ({ t }) => ({
      name: 'accuracy',
      dataField: 'accuracy',
      caption: 'reportAccuracy',
      calculateCellValue: (item) => formatPosition(item.accuracy, 'accuracy', t),
    }),
    motion: ({ t }) => ({
      name: 'motion',
      dataField: 'motion',
      caption: 'reportMotion',
      calculateCellValue: (item) => formatBoolean(item.attributes.motion, t),
    }),
    batteryLevel: ({ t }) => ({
      name: 'batteryLevel',
      dataField: 'batteryLevel',
      caption: 'reportBatteryLevel',
      calculateCellValue: (item) => formatPosition(item.attributes.batteryLevel, 'batteryLevel', t),
    }),
    valid: ({ t }) => ({
      name: 'valid',
      dataField: 'valid',
      caption: 'reportValid',
      calculateCellValue: (item) => (item.valid ? t('sharedValid') : t('sharedNotValid')),
    }),
    latitude: ({ coordinateFormat }) => ({
      name: 'latitude',
      dataField: 'latitude',
      caption: 'reportLatitude',
      calculateCellValue: (item) => formatPosition(item?.latitude, 'latitude', null, null, coordinateFormat),
    }),
    longitude: ({ coordinateFormat }) => ({
      name: 'longitude',
      dataField: 'longitude',
      caption: 'reportLongitude',
      calculateCellValue: (item) => formatPosition(item?.longitude, 'longitude', null, null, coordinateFormat),
    }),
    speed: ({ speedUnit, t }) => ({
      name: 'speed',
      dataField: 'speed',
      caption: 'positionSpeed',
      dataType: 'number',
      alignment: 'left',
      calculateCellValue: (item) => formatSpeed(item.speed, speedUnit, t),
    }),
    averageSpeed: ({ speedUnit, t }) => ({
      name: 'averageSpeed',
      dataField: 'averageSpeed',
      caption: 'reportAverageSpeed',
      calculateCellValue: (item) => formatSpeed(item.averageSpeed, speedUnit, t),
    }),
    maxSpeed: ({ speedUnit, t }) => ({
      name: 'maxSpeed',
      dataField: 'maxSpeed',
      caption: 'reportMaximumSpeed',
      calculateCellValue: (item) => formatSpeed(item.maxSpeed, speedUnit, t),
    }),
    startCoordinates: ({ coordinateFormat, t }) => ({
      name: 'startCoordinates',
      dataField: 'startCoordinates',
      caption: 'reportStartCoordinates',
      calculateCellValue: (item) => `${formatPosition(item.startLat, 'latitude', t, null, coordinateFormat)}, ${formatPosition(item.startLon, 'longitude', t, null, coordinateFormat)}`,
    }),
    endCoordinates: ({ coordinateFormat, t }) => ({
      name: 'endCoordinates',
      dataField: 'endCoordinates',
      caption: 'reportEndCoordinates',
      calculateCellValue: (item) => `${formatPosition(item.endLat, 'latitude', t, null, coordinateFormat)}, ${formatPosition(item.endLon, 'longitude', t, null, coordinateFormat)}`,
    }),
    distance: ({ distanceUnit, t }) => ({
      name: 'distance',
      dataField: 'distance',
      caption: 'reportDistance',
      calculateCellValue: (item) => formatDistance(item.distance, distanceUnit, t),
    }),
    duration: ({ t }) => ({
      name: 'duration',
      dataField: 'duration',
      caption: 'reportDuration',
      calculateCellValue: (item) => formatMsToTime(item.duration, t),
    }),
    eventType: ({ t }) => ({
      name: 'type',
      dataField: 'type',
      caption: 'reportType',
      calculateCellValue: (item) => t(prefixString('event', item.type)),
    }),
    geofence: ({ geofences }) => ({
      name: 'geofence',
      dataField: 'geofence',
      caption: 'reportGeofence',
      calculateCellValue: (item) => formatGeofence(geofences, item.geofenceId),
    }),
    direct: ({ t }) => ({
      name: 'type',
      dataField: 'type',
      caption: 'reportDirectMessage',
      calculateCellValue: (item) => formatSmsType(item.type, t),
    }),
    directLog: ({ t }) => ({
      name: 'direction',
      dataField: 'direction',
      caption: 'reportDirectMessage',
      calculateCellValue: (item) => formatSmsType(item.direction, t),
    }),
    sender: ({ devices, users }) => ({
      name: 'sender',
      dataField: 'sender',
      caption: 'reportSender',
      cellComponent: ({ data }) => <SenderCellRender data={data.data} devices={devices} users={users} />,
      calculateCellValue: (item) => {
        if (item.type === 'inbox') {
          return devices[item.deviceId]?.name;
        }
        if (item.type === 'outbox' && item.sendFromWeb) {
          return users.find((v) => v.id === item.userId)?.name;
        }
        return '';
      },
    }),
    recipient: ({ devices, users }) => ({
      name: 'recipient',
      dataField: 'recipient',
      caption: 'reportRecipient',
      calculateCellValue: (item) => (item.sendFromWeb ? devices[item.deviceId]?.name : users.find((v) => v.id === item.userId)?.name),
    }),
    level: ({ t }) => ({
      name: 'level',
      dataField: 'level',
      caption: 'reportLevel',
      calculateCellValue: (item) => t(prefixString('reportLogLevel', item.level)),
    }),
    course: {
      name: 'course',
      dataField: 'course',
      caption: 'positionCourse',
      dataType: 'number',
      alignment: 'left',
      calculateCellValue: (item) => {
        if (isUndefinedOrNull(item?.course) || item?.course === -1) {
          return '';
        }
        return tryConvertAndFixedNumber(item?.course);
      },
      customizeText: (text) => {
        if (isUndefinedOrNull(text.value) || text.value === '') {
          return '';
        }
        return `${text.value}°`;
      },
      // cellComponent: ({ data }) => <CustomizeCourseRender dataset={data} />,
    },
    login: { dataField: 'login', caption: 'userLogin' },
    email: { dataField: 'email', caption: 'userEmail' },

    attributes: {
      description: {
        dataField: 'attributes.description',
        caption: 'sharedDescription',
      },

      categoryParams: {
        sector: {
          dataField: 'attributes.categoryParams.sector',
          caption: 'positionSector',
          dataType: 'number',
          alignment: 'left',
        },
        centerFrequency: {
          dataField: 'attributes.categoryParams.centerFrequency',
          caption: 'deviceСenter_frequency_hz',
          dataType: 'number',
          alignment: 'left',
        },
        bandwidth: {
          dataField: 'attributes.categoryParams.bandwidth',
          caption: 'deviceBandwidth_hz',
          dataType: 'number',
          alignment: 'left',
        },
        averageSnr: {
          dataField: 'attributes.categoryParams.averageSnr',
          caption: 'positionAverageSnr',
          dataType: 'number',
          alignment: 'left',
          calculateCellValue: (item) => toFixedNumber(item.attributes?.categoryParams?.averageSnr),
        },
        peleng: {
          dataField: 'attributes.categoryParams.peleng',
          caption: 'positionPeleng',
          dataType: 'number',
          alignment: 'left',
          calculateCellValue: (item) => (item.attributes?.categoryParams?.peleng ? tryConvertAndFixedNumber(item.attributes?.categoryParams?.peleng) : ''),
          customizeText: (text) => {
            if (isUndefinedOrNull(text.value) || text.value === '') {
              return '';
            }
            return `${text.value}°`;
          },
        },
        height: {
          dataField: 'attributes.categoryParams.height',
          caption: 'globalAltitude',
          dataType: 'number',
          alignment: 'left',
        },
        elevationAngle: {
          dataField: 'attributes.categoryParams.elevationAngle',
          caption: 'globalPlaceAngle',
          dataType: 'number',
          alignment: 'left',
          calculateCellValue: (item) => tryConvertAndFixedNumber(item?.altitude),
        },
        dist: {
          dataField: 'attributes.categoryParams.dist',
          caption: 'positionDist',
          dataType: 'number',
          alignment: 'left',
        },
        zone: {
          dataField: 'attributes.categoryParams.zone',
          caption: 'positionZone',
        },
        comment: {
          dataField: 'attributes.categoryParams.comment',
          caption: 'positionComment',
        },
      },
    },
  },

  serverField: {
    serverTime: {
      dataField: 'serverTime',
      dataType: 'datetime',
      caption: 'reportDateAndTime',
      customizeText: (text) => getDateTime(text.value),
    },
    fixTime: {
      dataField: 'fixTime',
      dataType: 'datetime',
      caption: 'reportFixDateAndTime',
      customizeText: (text) => getDateTime(text.value),
    },
  },
};

import deviceCategories from '../static/deviceCategories';

export default {
  deviceToolOff: (value) => Array.isArray(value),
  eventReport: (value) => Array.isArray(value),
  closedDevices: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  closeNewDevices: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  devicesParams: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  hidePropertiesMain: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  hidePropertiesMainPopup: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  hidePropertiesStop: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  hidePropertiesStopPopup: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  language: (value) => typeof value === 'string',
  loginEmail: (value) => typeof value === 'string',
  panel: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  opacityMaps: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  extraLayersPanel: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  periodTail: (value) => typeof value === 'string',
  popupData: (value) => typeof value === 'boolean',
  eventsSoundOn: (value) => typeof value === 'boolean',
  tailOn: (value) => typeof value === 'boolean',
  routeReport: (value) => Array.isArray(value),
  selectedObjectCategoryOnMap: (value) => typeof value === 'string',
  detectedObjectReport: (value) => Array.isArray(value),
  selectedMap: (value) => typeof value === 'string',
  sorting: (value) => typeof value === 'string',
  sortingReverse: (value) => typeof value === 'string',
  stopReport: (value) => Array.isArray(value),
  zoomInitialized: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  deviceCategoriesOrder: (value) => typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length === Object.keys(deviceCategories).length,
  devextremeTablesSettings: (value) => typeof value === 'object',
  tailSelectedDevices: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  tailSelectedCategories: (value) => typeof value === 'object' && value !== null && !Array.isArray(value),
  websiteVersion: (value) => typeof value === 'object',
  smartFileManagerSettings: (value) => typeof value === 'object',
  videoMonitoringPanelSettings: (value) => typeof value === 'object',
  showPeriodSettings: (value) => typeof value === 'object',
  closedGroups: (value) => typeof value === 'object',
  lastSelectPeriod: (value) => typeof value === 'object',
  freeModalWindow: (value) => typeof value === 'object',
};

import detectedObjectsTable from './detectedTable/detectedObjectsTable';
import antiUavTable from './detectedTable/antiUavTable';
import radarsTable from './detectedTable/radarsTable';

import routeReport from './report/routeReport';
import radarReport from './report/radarReport';
import antiUavReport from './report/antiUavReport';
import detectedObjectsReport from './report/detectedObjectsReport';

import devicesSettings from './settings/devicesSettings';
import markersSettings from './settings/markersSettings';
import notificationsSettings from './settings/notificationsSettings';
import usersSettings from './settings/usersSettings';
import devicesGroupsSettings from './settings/devicesGroupsSettings';
import usersGroupsSettings from './settings/usersGroupsSettings';
import iconsSettings from './settings/iconsSettings';
import tripReport from './report/tripReport';
import stopReport from './report/stopReport';
import eventReport from './report/eventReport';
import smsReport from './report/smsReport';
import logReport from './report/logReport';
import smsTemplatesSettings from './settings/smsTemplatesSettings';

export default {
  detectedTable: {
    detectedObjectsTable,
    antiUavTable,
    radarsTable,
  },
  reportFields: {
    routeReport,
    radarReport,
    detectedObjectsReport,
    antiUavReport,
    tripReport,
    stopReport,
    eventReport,
    smsReport,
    logReport,
  },
  settingsFields: {
    devicesSettings,
    markersSettings,
    notificationsSettings,
    usersSettings,
    devicesGroupsSettings,
    usersGroupsSettings,
    smsTemplatesSettings,
    iconsSettings,
  },
};

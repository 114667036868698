/* eslint-disable object-curly-newline */
import { translateControlMode, translateWorkModeFromItem } from '../../funcs/calculateCellValue';
import baseProperties from '../shared/baseProperties';
import reportsSharedFields from './reportsSharedColumns';

export default ({ devicesObject, groups, t, theme, positions, devices }) => [
  ...reportsSharedFields.calculateValues.devicesShared({ devicesObject, groups, t, theme, devices }),
  ...reportsSharedFields.calculateValues.byCategory.radarAndAntiUav({ devices, t, positions }),
  {
    ...baseProperties,
    name: 'deviceControlMode',
    caption: 'deviceControlMode',
    calculateCellValue: (item) => translateControlMode(item, t),
    calculateGroupValue: (item) => translateControlMode(item, t),
  },
  {
    ...baseProperties,
    name: 'deviceWorkMode',
    caption: 'deviceWorkMode',
    calculateCellValue: (item) => translateWorkModeFromItem(item, t),
    calculateGroupValue: (item) => translateWorkModeFromItem(item, t),
  },
  {
    ...baseProperties,
    name: 'positionWorkModeDescription',
    caption: 'positionWorkModeDescription',
    calculateCellValue: (item) => positions[item.deviceId]?.attributes?.categoryParams?.workModeDescription,
    calculateGroupValue: (item) => positions[item.deviceId]?.attributes?.categoryParams?.workModeDescription,
  },
  // {
  //   ...baseProperties,
  //   name: 'deviceCurrentWorkMode',
  //   caption: 'deviceCurrentWorkMode',
  //   calculateCellValue: (item) => translateWorkModeFromItem(positions[item.deviceId], t),
  //   calculateGroupValue: (item) => translateWorkModeFromItem(positions[item.deviceId], t),
  // },
  ...reportsSharedFields.serverField,
];

import React, {
  useEffect, useState,
} from 'react';

import CloseIcon from '@material-ui/icons/Close';
import UndoIcon from '@material-ui/icons/Undo';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import LaunchIcon from '@material-ui/icons/Launch';
import TuneIcon from '@material-ui/icons/Tune';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { AttachFile } from '@material-ui/icons';
import {
  IconButton, Divider, Tooltip, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../LocalizationProvider';
import { dataPanelActions, devicesActions, positionsActions } from '../../../store';
import Data from './Data';
import ListDataStepper from './ListDataStepper';
import CompactPanel from './CompactPanel';
import SidePanel from './SidePanel';
import PositionDataEdit from './PositionDataEdit';
import { tryGetVcamPosition } from '../../utils/vcamHelper';

const useStyles = makeStyles((theme) => ({
  paperSidePanel: {
    height: '100%',
    width: '100%',
    paddingBottom: '0',
  },
  paperCompactPanel: {
    width: '100%',
    height: 'calc(100% - 34px)',
  },
  edit: {
    marginLeft: 'auto',
  },
  iconButton: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: '4px',
  },
  activeSubMenu: {
    color: '#c51228',
  },
}));

const DataPanel = ({
  // eslint-disable-next-line no-unused-vars
  setNextPosition, countPosition, setCountPosition, data, redFields,
  setPositionsBS, off, includeProperties, speedUnit, positionsSimilar,
  popup, setPopup, isCompactPanel, selectedMarkerComponents,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const selectedPositionData = tryGetVcamPosition(
    useSelector((state) => state.positions.selectedPositionData),
    useSelector((state) => state.devices.items),
  );

  const isOpenAttachedFiles = useSelector((state) => state.dataPanel.isOpenAttachedFiles);
  const isOpenChoice = useSelector((state) => state.dataPanel.isOpenChoice);
  const isOpenEditForm = useSelector((state) => state.dataPanel.isOpenEditForm);
  const isOpenExtraSettings = useSelector((state) => state.dataPanel.isOpenExtraSettings);

  const isHaveList = !!(selectedPositionData.numberPoints > 1 && !selectedPositionData.startPoint);
  const bodyContentOutside = selectedMarkerComponents.bodyContent;
  const headerSidebarOutside = selectedMarkerComponents.sidebarHeader;
  const headerCompactOutside = selectedMarkerComponents.compactHeader;

  useEffect(() => {
    if (selectedPositionData.categoryParams === undefined || Object.keys(selectedPositionData.categoryParams).length === 0) {
      dispatch(dataPanelActions.closeTab('isOpenExtraSettings'));
    }
  }, [selectedPositionData]);

  const removePopup = () => {
    try {
      if (popup) {
        popup.remove();
        setPopup(null);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useState(() => {
    if (selectedMarkerComponents.callbackDeletePopup) {
      selectedMarkerComponents.callbackDeletePopup.current = removePopup;
    }
  }, []);

  const handleClose = () => {
    if (isOpenAttachedFiles || isOpenChoice || isOpenExtraSettings) {
      dispatch(dataPanelActions.closeTabs());
    } else {
      dispatch(positionsActions.changeTrackingMode(false));
      dispatch(positionsActions.needToShowDataPanel(false));
      dispatch(positionsActions.unselectPosition());
      dispatch(devicesActions.deselect(null));
      setNextPosition(null);
      setPositionsBS([]);
      removePopup();
    }
  };

  const handleExpand = () => {
    if (isCompactPanel) {
      dispatch(positionsActions.updatePopupData(false));
    } else {
      dispatch(positionsActions.updatePopupData(true));
    }
  };

  const panelHeader = (
    <>
      {selectedPositionData.deviceId
        && (
          <Tooltip title={t('globalAttachments')} className={isOpenAttachedFiles && classes.activeSubMenu}>
            <IconButton
              size={isCompactPanel && 'small'}
              onClick={() => { dispatch(dataPanelActions.switchTab('isOpenAttachedFiles')); }}
            >
              <AttachFile />
            </IconButton>
          </Tooltip>
        )}
      <Tooltip title={t('settingsTitle')} className={isOpenChoice && classes.activeSubMenu}>
        <IconButton
          size={isCompactPanel && 'small'}
          onClick={() => { dispatch(dataPanelActions.switchTab('isOpenChoice')); }}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      {selectedPositionData.deviceId
        && (
          <Tooltip title={t('sharedEdit')} className={isOpenEditForm && classes.activeSubMenu}>
            <IconButton
              size={isCompactPanel && 'small'}
              onClick={() => { dispatch(dataPanelActions.openTab('isOpenEditForm')); }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

      {(selectedPositionData.categoryParams === undefined || Object.keys(selectedPositionData.categoryParams).length === 0) ? <></>
        : (
          <Tooltip title={t('panelExtra')} className={isOpenExtraSettings && classes.activeSubMenu}>
            <IconButton
              size={isCompactPanel && 'small'}
              onClick={() => { dispatch(dataPanelActions.switchTab('isOpenExtraSettings')); }}
            >
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
      <Tooltip title={isCompactPanel ? t('expandPanelTitle') : t('shrinkPanelTitle')}>
        <IconButton
          size={isCompactPanel && 'small'}
          onClick={handleExpand}
        >
          {isCompactPanel
            ? <ZoomOutMapIcon fontSize="small" />
            : <LaunchIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title={t('closeTitle')}>
        <IconButton
          size={isCompactPanel && 'small'}
          onClick={handleClose}
        >
          {(isOpenAttachedFiles || isOpenChoice || isOpenExtraSettings)
            ? <UndoIcon />
            : <CloseIcon />}
        </IconButton>
      </Tooltip>
    </>
  );

  const bodyContent = (
    <>
      <Data
        rootClass={isCompactPanel ? classes.paperCompactPanel : classes.paperSidePanel}
        size={isCompactPanel ? 'small' : 'medium'}
        data={data}
        off={off}
        includeProperties={includeProperties}
        redFields={redFields}
        speedUnit={speedUnit}
        isOpenChoice={isOpenChoice}
        isOpenExtraSettings={isOpenExtraSettings}
        isOpenAttachedFiles={isOpenAttachedFiles}
        closeAttachedFilesWindow={() => { dispatch(dataPanelActions.closeTab('isOpenAttachedFiles')); }}
      />
      {isHaveList && (
        <>
          <Divider />
          <ListDataStepper
            gridStyle={{ padding: isCompactPanel ? '0' : '5px' }}
            classes={classes}
            countPosition={countPosition}
            positionsSimilar={positionsSimilar}
            setCountPosition={setCountPosition}
            setNextPosition={setNextPosition}
          />
        </>
      )}
    </>
  );

  return (
    <>
      {isCompactPanel ? (
        <CompactPanel
          paperStyle={{ marginBottom: isHaveList ? '26px' : '0' }}
          headerContent={headerCompactOutside ?? panelHeader}
          bodyContent={bodyContentOutside ?? bodyContent}
        />
      ) : (
        <SidePanel
          headerContent={headerSidebarOutside ?? panelHeader}
          bodyContent={bodyContentOutside ?? bodyContent}
          upper
        />
      )}

      {isOpenEditForm && (
        <PositionDataEdit
          deviceProperties={selectedPositionData}
          open={isOpenEditForm}
          onClose={() => { dispatch(dataPanelActions.closeTab('isOpenEditForm')); }}
        />
      )}
    </>
  );
};

export default DataPanel;

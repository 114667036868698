import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  abortRequest,
  collectReport,
} from '../../common/utils/formatter';
import usePersistedState from '../../common/utils/usePersistedState';
import { getIsAdmin } from '../../common/utils/selectors';
import { useEffectAsync } from '../../common/utils/reactHelper';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { errorsActions } from '../../store';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';

const typeReport = 'smsReport';

const columnsArray = [];

const SmsReportPage = () => {
  const category = 'sms';
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [page, setPage] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();

  const templateField = useTemplatesFieldsPresetForReports(devicesObject, category, undefined, undefined, undefined, users);
  const reportColumns = useAutoCompleteColumns(templateField);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const stylesRow = (e) => {
    if (e.rowType === 'data') {
      if (e.data.type === 'outbox') {
        e.rowElement.style.backgroundColor = 'lightyellow';
      }
      if (e.data.type === 'inbox') {
        e.rowElement.style.backgroundColor = 'lightblue';
      }
    }
  };

  const handleSubmit = (deviceId, from, to, headers, hideLoadingForm) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        query.append('all', true);
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        setProgress(1);
        const response = await fetch(`/api/reports/sms-history?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              hideLoadingForm();
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);
        hideLoadingForm();
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgress(0);
        hideLoadingForm();
      }
    };
    apiCall();
  };

  useEffectAsync(async () => {
    if (isAdmin) {
      const response = await fetch('/api/users');
      if (response.ok) {
        setUsers(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, []);

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          setProgressDevices(true);
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);

            const resultObj = {};
            result.forEach((item) => resultObj[item.id] = item);
            setDevicesObject(resultObj);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
          setProgressDevices(false);
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <>
      <ReportTemplate
        items={items}
        withoutDevices
        progress={progress}
        progressDevices={progressDevices}
        columnsArray={columnsArray}
        handleSubmit={handleSubmit}
        page={page}
        reportColumns={reportColumns}
        typeReport={typeReport}
        offColumns={offColumns}
        setOffColumns={setOffColumns}
        breadcrumbs={['reportTitle', 'reportSms']}
        devicesList={devicesList}
        devicesObject={devicesObject}
        countProgress={countProgress}
        progressReport={progressReport}
        periodChosen={periodChosen}
        setPeriodChosen={setPeriodChosen}
        stylesRow={stylesRow}
      />
    </>
  );
};

export default SmsReportPage;

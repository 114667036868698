import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import BottomMenu from './BottomMenu';
import ConfirmExit from './ConfirmExit';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
}));

const DenySession = () => {
  const classes = useStyles();
  const location = useLocation();
  const t = useTranslation();
  const sessionAllowed = useSelector((state) => state.session.sessionAllowed);
  const user = useSelector((state) => state.session.user);
  const authenticated = useSelector((state) => !!state.session.user);
  const [openConfirmExit, setOpenConfirmExit] = useState(false);

  const isSettingsPage = location.pathname === '/settings';
  const isSystemPage = location.pathname === '/admin/system';
  const isUsersPage = location.pathname === '/admin/users';
  const isServerPage = location.pathname === '/admin/server';
  const isUserPage = /^\/user\/\d+$/.test(location.pathname);

  if (sessionAllowed !== false) {
    return null;
  }

  if (authenticated) {
    if (user?.administrator) {
      if (isSettingsPage || isSystemPage || isUsersPage || isUserPage || isServerPage) {
        return null;
      }
    }
  }

  return (
    <Modal open={sessionAllowed === false} className={classes.modal}>
      <>
        <Alert elevation={6} severity="error" variant="filled" className={classes.alert}>
          {user?.administrator
            ? t('limitUsersAdminErrorMsg')
            : t('limitUsersErrorMsg')}
        </Alert>
        <BottomMenu setOpenConfirmExit={setOpenConfirmExit} />
        <ConfirmExit open={openConfirmExit} setOpen={setOpenConfirmExit} />
      </>
    </Modal>
  );
};

export default DenySession;

import React, { useMemo } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import { DropDownBox, TreeView } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';

const TreeViewCombobox = ({
  datasetCallback, disabled, label, value,
  displayExpr, parentIdExpr, valueExpr, keyExpr,
  mode = 'text', height = 56, dataStructure = 'plain', selectionMode = 'multiple', showCheckBoxesMode = 'normal',
  searchEnabled = true, selectByClick = true,
  onSelectionChangedKeys, onSelectionChangedValues,
}) => {
  const dataSource = useMemo(() => new ArrayStore({
    data: datasetCallback(),
    key: keyExpr,
  }), [datasetCallback]);

  const treeViewItemSelectionChanged = (e) => {
    if (onSelectionChangedKeys) {
      onSelectionChangedKeys(e.component.getSelectedNodeKeys());
    }
    if (onSelectionChangedValues) {
      onSelectionChangedValues(e.component.getSelectedNodes());
    }
  };

  const treeViewRender = () => (
    <TreeView
      disabled={disabled}
      dataSource={dataSource}
      displayExpr={displayExpr}
      parentIdExpr={parentIdExpr}
      keyExpr={keyExpr}
      dataStructure={dataStructure}
      selectionMode={selectionMode}
      showCheckBoxesMode={showCheckBoxesMode}
      searchEnabled={searchEnabled}
      selectByClick={selectByClick}
      onItemSelectionChanged={treeViewItemSelectionChanged}
    />
  );

  return (
    <DropDownBox
      style={{ marginTop: 0, background: 'transparent' }}
      height={height}
      disabled={disabled}
      value={value}
      label={label}
      displayExpr={displayExpr}
      valueExpr={valueExpr}
      keyExpr={keyExpr}
      mode={mode}
      dataSource={dataSource}
      contentRender={treeViewRender}
    />
  );
};

export default TreeViewCombobox;

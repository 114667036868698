import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import SuperMenuItem from '../components/SuperMenuItem';
import { useTranslation } from '../components/LocalizationProvider';

const ELEMENT_HEIGHT = 48;
const MIN_CONTAINER_HEIGHT = 360;

const MultiSelectDropdown = ({
  label,
  data,
  value,
  setValue,
  emptyText,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const filteredItems = data.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase()));

  const handleItemChange = (item) => {
    const updatedItems = value.includes(item)
      ? value.filter((i) => i !== item)
      : [...value, item];
    setValue(updatedItems);
  };

  const handleSelectAll = () => {
    const allSelected = value.length === data.length;
    setValue(allSelected ? [] : data);
  };

  const menuItemLazyLoad = ({ index, style }) => (
    <MenuItem
      style={style}
      value={filteredItems[index]}
      key={filteredItems[index]}
      onClick={() => handleItemChange(filteredItems[index])}
    >
      <Checkbox checked={value.includes(filteredItems[index])} />
      <ListItemText primary={filteredItems[index]} />
    </MenuItem>
  );

  const calcHeight = () => (filteredItems.length >= 7 ? MIN_CONTAINER_HEIGHT : filteredItems.length * ELEMENT_HEIGHT);

  return (
    <FormControl margin="normal" variant="filled" fullWidth>
      <InputLabel shrink={!!value.length || !!emptyText}>{label}</InputLabel>
      <Select
        multiple
        displayEmpty={!!emptyText}
        value={value}
        renderValue={(selected) => (selected.length > 0 ? `${selected.length} ${t('sharedNumbers')}` : emptyText || '')}
        MenuProps={theme.overrides?.MenuProps}
      >
        <Box
          style={{
            marginTop: '-10px',
            backgroundColor: theme.palette.grey[300],
            paddingLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <MenuItem value="all" onClick={handleSelectAll}>
            <SuperMenuItem all={value.length === data.length} />
          </MenuItem>
          <TextField
            style={{ backgroundColor: '#fff', borderRadius: '5px', marginRight: '8px' }}
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder={t('sharedSearch')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <AutoSizer disableHeight>
          {({ width }) => (
            <FixedSizeList
              height={calcHeight()}
              width={width}
              itemCount={filteredItems.length}
              itemSize={ELEMENT_HEIGHT}
            >
              {menuItemLazyLoad}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;

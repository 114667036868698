import React, { useCallback, useState, useEffect } from 'react';
import {
  Button, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import PageLayout from '../../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { downloadFileByUrl } from '../../../common/utils/fileHelper';
import VideoCameraBlocksContainer from '../components/VideoCameraBlocksContainer';
import VideoCameraBlock from '../components/VideoCameraBlock';
import VideoCameraActionsWindows from '../components/VideoCameraActionsWindows';
import VideoCameraExportLayer from '../components/VideoCameraExportLayer';
import VideoCamerasFiltersPanel from '../components/VideoCamerasFiltersPanel';
import VideoCameraLayer from '../components/VideoCameraLayer';

const useStyles = makeStyles(() => ({
  noData: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filters: {
    margin: '0 8px',
  },
}));

const breadcrumbs = ['reportTitle', 'reportVideoCameraRecording'];

const VideoCameraExportsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [videoCameraExportsFiltered, setVideoCameraExportsFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [datetimeFilter, setDatetimeFilter] = useState({ from: undefined, to: undefined });
  const [videoCamerasIds, setVideoCamerasIds] = useState([]);
  const [selectedVideoCameras, setSelectedVideoCameras] = useState([]);

  const videoCameraLayer = VideoCameraLayer({});
  const exportLayer = VideoCameraExportLayer({ videoCameraLayer });

  const createCameras = useCallback(() => {
    const cameras = [];
    const videoCamerasMetadata = videoCameraLayer.getVideoCamerasMetadata();
    for (const cameraId of videoCamerasIds) {
      const { nameDisplayed } = videoCamerasMetadata[cameraId];
      cameras.push({ name: nameDisplayed, ID: nameDisplayed, selected: selectedVideoCameras.includes(nameDisplayed) });
    }
    return cameras;
  }, [videoCamerasIds, selectedVideoCameras]);

  const treeViewItemSelectionChanged = (items) => {
    setSelectedVideoCameras(items);
  };

  const acceptDeleteConfirmWindow = async (videoItem) => {
    try {
      const { videoRecordService, id } = videoItem;
      const url = `${videoRecordService}/api/export/${id}`;
      const response = await fetch(url, { method: 'DELETE' });

      if (response.ok) {
        setVideoCameraExportsFiltered((prev) => {
          const newVideos = prev.filter((v) => v.id !== id);
          return newVideos;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const videoCameraActionsWindows = VideoCameraActionsWindows({
    onDeleteConfirm: acceptDeleteConfirmWindow,
  });

  const errorHandler = (fn) => async () => {
    try {
      setIsLoading(true);

      await fn();

      if (isError) {
        setIsError(false);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadVideosExports = async () => {
    const _ = async () => {
      const isSelectedVideoCameras = selectedVideoCameras.length > 0;
      const isSelectedDatetimeFilter = datetimeFilter?.from;

      let exportedVideos = exportLayer.getExportedVideos();
      if (isSelectedVideoCameras) {
        exportedVideos = exportedVideos.filter((e) => selectedVideoCameras.includes(e.nameDisplayed));
      }
      if (isSelectedDatetimeFilter) {
        // startTime и endTime заполняются в VideoCameraExportLayer
        exportedVideos = exportedVideos.filter((e) => e.startTime >= moment(datetimeFilter.from).unix() && e.endTime <= moment(datetimeFilter.to).unix());
      }
      setVideoCameraExportsFiltered(exportedVideos);
    };

    const method = errorHandler(_);
    await method();
  };

  const loadPageData = async () => {
    const _ = async () => {
      await videoCameraLayer.loadVideoCameras();
      await exportLayer.loadVideosExports();
      setVideoCameraExportsFiltered(exportLayer.getExportedVideos());
      setVideoCamerasIds(videoCameraLayer.getVideoCameraUniqueIds());
    };

    const method = errorHandler(_);
    await method();
  };

  useEffect(() => {
    loadPageData();
  }, []);

  const handleApplyFilter = () => {
    loadVideosExports();
  };

  const handleResetFilter = () => {
    setDatetimeFilter(undefined);
    setSelectedVideoCameras([]);
    setVideoCameraExportsFiltered(exportLayer.getExportedVideos());
  };

  const handleSaveVideo = async (videoItem, startDownload, endDownload) => {
    startDownload();

    try {
      await downloadFileByUrl(
        `${videoItem.videoRecordService}/exports/${videoItem.id}.mp4`,
        `${videoItem.name}.mp4`,
      );
    } catch (error) {
      console.error(error);
    }

    endDownload();
  };

  const handleRemoveVideo = (videoItem) => {
    videoCameraActionsWindows.setDataForWindows({
      ...videoItem,
      text: videoItem.mainText,
    });
    videoCameraActionsWindows.openDeleteConfirmWindow();
  };

  const handlePlayVideo = (videoItem) => {
    videoCameraActionsWindows.setDataForWindows({
      ...videoItem,
      srcUrl: videoItem.videoUrl,
      text: videoItem.mainText,
    });
    videoCameraActionsWindows.openVideoPreviewWindow();
  };

  if (isError) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <div style={{ flexDirection: 'column' }} className={classes.noData}>
          <div>{t('globalErrorOccurredReceiving')}</div>
          <Button disabled={isLoading} variant="outlined" color="primary" onClick={loadPageData}>
            {t('globalAgain')}
          </Button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      {videoCameraActionsWindows.ActionsWindows}

      <div className={classes.filters}>
        <VideoCamerasFiltersPanel
          datetimeFilter={datetimeFilter}
          setDatetimeFilter={setDatetimeFilter}
          createCameras={createCameras}
          selectedVideoCameras={selectedVideoCameras}
          treeViewItemSelectionChanged={treeViewItemSelectionChanged}
          onApplyFilter={handleApplyFilter}
          onResetFilter={handleResetFilter}
        />
      </div>

      <VideoCameraBlocksContainer
        isLoading={isLoading}
        isNoData={videoCameraExportsFiltered.length === 0}
        datesetForPagination={videoCameraExportsFiltered}
        callbackForPagination={(videoCameraItem) => (
          <VideoCameraBlock
            key={videoCameraItem.id}
            videoCameraItem={{
              ...videoCameraItem,
              id: videoCameraItem.id,
              srcUrl: videoCameraItem.imgUrl,
              mainText: videoCameraItem.name,
              inProcess: videoCameraItem.in_progress,
            }}
            onPlayVideo={handlePlayVideo}
            onRemoveVideo={handleRemoveVideo}
            onSaveVideo={handleSaveVideo}
          />
        )}
      />
    </PageLayout>
  );
};

export default VideoCameraExportsPage;

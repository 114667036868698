import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  TextField, Button, makeStyles, CircularProgress, Tooltip, List, ListItem, ListItemText, Collapse,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SendIcon from '@material-ui/icons/Send';
import TemplateIcon from '@material-ui/icons/ListAlt';
import { Skeleton } from '@material-ui/lab';
import FreeModalWindow from './FreeModalWindow';
import scrollStyles from '../theme/scrollStyles';
import logout from '../utils/logout';
import { useEffectAsync } from '../utils/reactHelper';
import { smsActions } from '../../store';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    padding: '10px',
    marginBottom: '10px',
    ...scrollStyles(5),
  },
  skeleton: {
    marginBottom: 10,
    borderRadius: 10,
  },
  message: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '10px',
    wordWrap: 'break-word',
    display: 'flex',
    flexDirection: 'column',
  },
  inboxMessage: {
    backgroundColor: 'lightblue',
    alignSelf: 'flex-start',
  },
  outboxMessage: {
    backgroundColor: 'lightyellow',
    alignSelf: 'flex-end',
  },
  statusIcon: {
    alignSelf: 'center',
    marginRight: '8px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #ddd',
  },
  inputField: {
    flex: 1,
    marginRight: '10px',
  },
  templatesContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    borderTop: '1px solid #ddd',
    backgroundColor: '#f5f5f5',
    ...scrollStyles(5),
    transition: 'max-height 0.3s ease',
  },
}));

const SmsChat = ({ deviceId }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [device, setDevice] = useState({});
  const [showTemplates, setShowTemplates] = useState(false);
  const newChatSms = useSelector((state) => state.sms.newChatSms);
  const unreadSms = useSelector((state) => state.sms.items);

  const templates = [
    {
      id: 1,
      name: 'кмнд1',
      protocols: ['прткл1'],
      template: 'смс команда 1',
      description: 'Описание для 1ой команды',
    },
    {
      id: 2,
      name: 'кмнд 2',
      protocols: ['прткл3'],
      template: 'смс команда 2',
      description: 'Описание для 2ой команды',
    },
    {
      id: 3,
      name: 'кмнд 3',
      protocols: ['прткл1'],
      template: 'смс команда 3',
      description: 'Описание для 3ой команды',
    },
    {
      id: 4,
      name: 'кмнд 4',
      protocols: ['прткл1'],
      template: 'смс команда 4',
      description: 'Описание для 4ой команды',
    },
    {
      id: 5,
      name: 'кмнд 5',
      protocols: ['прткл1'],
      template: 'смс команда 5',
      description: 'Описание для 5ой команды',
    },
    {
      id: 6,
      name: 'кмнд 6',
      protocols: ['прткл1'],
      template: 'смс команда 6',
      description: 'Описание для 6ой команды',
    },
  ];

  useEffect(() => {
    unreadSms.forEach((sms) => {
      if (sms.deviceId === deviceId) {
        fetch(`/api/smsstatus/gotten/${sms.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
        });
        dispatch(smsActions.remove(sms.id));
      }
    });
  }, []);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!loading) {
      scrollToBottom();
    }
  }, [loading, messages]);

  useEffect(() => {
    if (newChatSms[deviceId] && newChatSms[deviceId].length !== 0) {
      setMessages((prevMessages) => [...prevMessages, ...newChatSms[deviceId]]);
      dispatch(smsActions.removeSmsChatMsg(deviceId));
    }
  }, [newChatSms]);

  useEffectAsync(async () => {
    setLoading(true);
    const responseDevice = await fetch(`/api/devices/${deviceId}`);
    if (responseDevice.ok) {
      const data = await responseDevice.json();
      setDevice(data);
    } else if (responseDevice.status === 401) {
      logout(history, dispatch);
    }

    const from = moment().subtract(1, 'month').toISOString();
    const to = moment().toISOString();
    const query = new URLSearchParams({
      from, to,
    });
    query.append('deviceId', deviceId);
    const response = await fetch(`/api/reports/sms-history?${query.toString()}`);
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          const result = await response.json();
          const messages = [];
          result.forEach((message) => {
            if (!(message.type === 'outbox' && message.sendFromWeb === false)) {
              messages.push(message);
            }
          });
          setMessages(messages);
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setLoading(false);
  }, [deviceId]);

  const sendMessage = async (message) => {
    try {
      const response = await fetch('/api/commands/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: 0,
          deviceId,
          type: 'custom',
          attributes: { data: message.message },
          textChannel: true,
        }),
      });

      if (!response.ok) throw new Error('Send error');

      setMessages((prevMessages) => prevMessages.map((msg) => (msg.id === message.id ? { ...msg, status: 'sent' } : msg)));
    } catch (error) {
      setMessages((prevMessages) => prevMessages.map((msg) => (msg.id === message.id ? { ...msg, status: 'error' } : msg)));
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const tempMessage = {
      id: Date.now(),
      smsTime: Date.now(),
      message: newMessage,
      type: 'outbox',
      deviceId,
      senderPhone: '+79111111111',
      recipientPhone: device.phone,
      status: 'sending',
    };
    setMessages((prevMessages) => [...prevMessages, tempMessage]);
    await sendMessage(tempMessage);
    setNewMessage('');
  };

  const handleResendMessage = async (messageId) => {
    const messageToResend = messages.find((msg) => msg.id === messageId);
    if (!messageToResend) return;

    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));

    const updatedMessage = { ...messageToResend, smsTime: Date.now(), status: 'sending' };
    setMessages((prevMessages) => [...prevMessages, updatedMessage]);
    await sendMessage(updatedMessage, true);
  };

  const handleTemplateClick = (template) => {
    setNewMessage(template.template);
    setShowTemplates(false);
  };

  return (
    <FreeModalWindow
      title={device.name || t('sharedLoading')}
      enableFullscreenMode
      enableResizable
      enableSelect
      enableScroll
      disableBodyPadding
      onCloseModalWindow={() => { dispatch(smsActions.removeSmsChat(device.id)); }}
    >
      <div className={classes.chatContainer}>
        <div className={classes.messagesContainer}>
          {loading ? (
            <>
              <Skeleton className={classes.skeleton} variant="rect" height={60} width={400} />
              <Skeleton className={classes.skeleton} variant="rect" height={60} width={250} />

              <Skeleton className={classes.skeleton} variant="rect" height={60} width={300} style={{ alignSelf: 'flex-end' }} />
              <Skeleton className={classes.skeleton} variant="rect" height={60} width={250} style={{ alignSelf: 'flex-end' }} />

              <Skeleton className={classes.skeleton} variant="rect" height={60} width={350} />
              <Skeleton className={classes.skeleton} variant="rect" height={60} width={250} style={{ alignSelf: 'flex-end' }} />

            </>
          ) : (
            messages.map((message) => (
              <div
                key={message.id}
                className={`${classes.message} ${message.type === 'inbox' ? classes.inboxMessage : classes.outboxMessage}`}
              >
                <div style={{ display: 'flex' }}>
                  {message.status === 'sending' && <CircularProgress size={20} className={classes.statusIcon} />}
                  {message.status === 'error' && (
                    <Tooltip title={t('errorSendSMS')} style={{ cursor: 'pointer' }}>
                      <ErrorIcon
                        color="error"
                        onClick={() => handleResendMessage(message.id)}
                        className={classes.statusIcon}
                      />
                    </Tooltip>
                  )}
                  <div>
                    {message.message}
                  </div>
                </div>
                <div style={{ fontSize: '12px', color: 'gray', marginTop: '5px' }}>
                  {moment(message.smsTime).format('DD.MM.YYYY HH:mm')}
                </div>
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </div>
        <Collapse in={showTemplates}>
          <div className={classes.templatesContainer}>
            <List>
              {templates && templates.length !== 0
                ? templates.map((template) => (
                  <ListItem button key={template.id} onClick={() => handleTemplateClick(template)}>
                    <ListItemText
                      primary={(
                        <>
                          <Typography component="span" style={{ fontWeight: 'bold' }}>
                            {`${template.name} | `}
                          </Typography>
                          {template.template}
                        </>
                      )}
                      secondary={template.description}
                    />
                  </ListItem>
                ))
                : (
                  <ListItem>
                    {t('errorNoSmsTemplates')}
                  </ListItem>
                )}
            </List>
          </div>
        </Collapse>
        <div className={classes.inputContainer}>
          {/* <Tooltip title={t('sharedSmsTemplates')}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowTemplates(!showTemplates)}
              style={{ marginRight: '10px', height: 55 }}
            >
              <TemplateIcon />
            </Button>
          </Tooltip> */}
          <TextField
            className={classes.inputField}
            variant="outlined"
            placeholder={t('smsInputPlaceholder')}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSendMessage();
            }}
          />
          <Tooltip title={t('commandSend')}>
            <Button style={{ height: 55 }} variant="contained" color="primary" onClick={handleSendMessage}>
              <SendIcon />
            </Button>
          </Tooltip>

        </div>
      </div>
    </FreeModalWindow>
  );
};

export default SmsChat;

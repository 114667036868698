import React, { memo } from 'react';
import {
  useMediaQuery, useTheme,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { Popup } from 'devextreme-react';
import SimpleVideoPlayer from '../../../common/components/SimpleVideoPlayer';

const VideoPreviewWindow = ({
  title, url, closeWindow,
  defaultWidth = '50%',
  defaultHeight = '50%',
  type = 'video/mp4',
  additionalOptions = {
    disablePictureInPicture: true,
    controlBar: {
      skipButtons: {
        backward: 10,
        forward: 10,
      },
    },
  },
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Popup
      visible
      defaultWidth={defaultWidth}
      defaultHeight={defaultHeight}
      resizeEnabled
      dragEnabled
      fullScreen={isMobile}
      position="center"
      title={title}
      showTitle
      shadingColor="rgba(80 80 80 / 10%)"
      closeOnOutsideClick
      onHiding={closeWindow}
    >
      <SimpleVideoPlayer url={url} type={type} additionalOptions={additionalOptions} />
    </Popup>
  );
};

export default memo(VideoPreviewWindow);

import React from 'react';
import {
  LinearProgress,
  Typography, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import scrollStyles from '../../../common/theme/scrollStyles';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';
import useSimplePagination from '../../../common/hooks/useSimplePagination';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    margin: 5,
    padding: 3,
    ...scrollStyles(6),
  },
  cameraElementsGridFull: {
    margin: '5px 0',
    display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(360px, 1fr))',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    gap: 5,
  },
  cameraElementsGridHorizontal: {
    display: 'flex',
    gap: 5,
    width: '100%',
    height: '100%',
  },
  noData: {
    height: '90%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pagination: {
    overflow: 'hidden',
  },
}));

export const gridModeEnum = Object.freeze({
  Full: 'full',
  Horizontal: 'horizontal',
});

const VideoCameraBlocksContainer = ({
  isNoData = false,
  isLoading = false,
  datesetForPagination = undefined, callbackForPagination = undefined,
  gridMode = gridModeEnum.Full,
  containerStyle, gridStyle,
  containerClass, gridClass,
  children,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const getClass = (cls) => cls.join(' ');

  const gridClasses = gridMode === gridModeEnum.Full ? classes.cameraElementsGridFull : classes.cameraElementsGridHorizontal;

  const enablePagination = !isUndefinedOrNull(datesetForPagination);
  const datesetCount = enablePagination ? datesetForPagination.length : 0;
  const noDataToDisplay = enablePagination ? datesetCount === 0 : isNoData;

  const [Pagination, getProcessedDataset] = useSimplePagination({ datesetForPagination: enablePagination ? datesetForPagination : [] });

  if (isLoading) {
    return (
      <LinearProgress />
    );
  }

  return (
    <>
      <div style={containerStyle && containerStyle} className={getClass([classes.container, containerClass])}>
        {(noDataToDisplay) ? (
          <div className={classes.noData}>
            <Typography variant="h6">{t('globalNoDataToDisplay')}</Typography>
          </div>
        ) : (
          <>
            <div style={gridStyle && gridStyle} className={getClass([gridClasses, gridClass])}>
              {enablePagination ? (
                <>{getProcessedDataset().map((data) => callbackForPagination(data))}</>
              ) : (
                children
              )}
            </div>
          </>
        )}
      </div>

      {enablePagination && datesetCount > 0 && (
        <div className={classes.pagination}>
          {Pagination}
        </div>
      )}
    </>
  );
};

export default VideoCameraBlocksContainer;

import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    server: null,
    user: null,
    initialized: false,
    timeExpired: false,
    timeExpiration: null,
    socketOpened: false,
    sessionAllowed: null,
    servicesVersions: {},
  },
  reducers: {
    allowSession(state, action) {
      state.sessionAllowed = action.payload;
    },
    updateServer(state, action) {
      state.server = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateInitialized(state, action) {
      state.initialized = action.payload;
    },
    updateTimeExpired(state, action) {
      state.timeExpired = action.payload;
    },
    updateTimeExpiration(state, action) {
      state.timeExpiration = action.payload;
    },
    updateSocketOpened(state, action) {
      state.socketOpened = action.payload;
    },
    updateServicesVersions(state, action) {
      state.servicesVersions = { ...state.servicesVersions, ...action.payload };
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };

import { errorsActions, mapActions } from '../../store';

export default async function fetchWithoutCluster(map, url, autoRefresh, id, name, signal, decrementGeoJsonCount, incrementGeoJsonCount, updateLoadedMaps, dispatch) {
  try {
    incrementGeoJsonCount();
    let response;
    try {
      response = await fetch(url, { signal });
    } catch (error) {
      decrementGeoJsonCount();
      if (error.name !== 'AbortError') {
        console.error(error);
        dispatch(errorsActions.push(`Не удалось загрузить доп. слой: ${name}`));
      }
      return;
    }

    if (response.ok) {
      const data = await response.json();
      if (map.getSource(id)) {
        map.getSource(id).setData(data);
        dispatch(mapActions.updateFeatureCount({ id, count: data.features.length }));

        if (autoRefresh) {
          setTimeout((() => {
            fetchWithoutCluster(map, url, autoRefresh, id, signal, decrementGeoJsonCount, incrementGeoJsonCount, updateLoadedMaps, dispatch);
          }), autoRefresh * 60 * 1000);
        }
      }
    }
    updateLoadedMaps();
    decrementGeoJsonCount();
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error(error);
      dispatch(errorsActions.push(`Не удалось загрузить доп. слой: ${name}`));
    }
    decrementGeoJsonCount();
  }
}

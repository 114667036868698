import fetchWithCluster from './fetchWithCluster';
import fetchWithoutCluster from './fetchWithoutCluster';

export default function handleFetchGeoJson(
  map,
  url,
  autoRefresh,
  extraMapId,
  extraMapName,
  signal,
  decrementGeoJsonCount,
  incrementGeoJsonCount,
  dispatch,
  onCluster,
  moveEndListenersRef,
  updateLoadedMaps,
) {
  if (onCluster) {
    fetchWithCluster(map, url, autoRefresh, extraMapId, extraMapName, signal, decrementGeoJsonCount, incrementGeoJsonCount, updateLoadedMaps, dispatch)
      .then((listener) => {
        moveEndListenersRef.current[extraMapId] = listener;
      });
  } else {
    fetchWithoutCluster(map, url, autoRefresh, extraMapId, extraMapName, signal, decrementGeoJsonCount, incrementGeoJsonCount, updateLoadedMaps, dispatch);
  }
}

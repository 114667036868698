/* eslint-disable object-curly-newline */
import { formatProtocols } from '../../../../utils/formatter';
import {
  getGroupName,
} from '../../funcs/calculateCellValue';
import baseProperties from '../shared/baseProperties';
import presetsColumns from '../shared/presetsColumns';

export default {
  calculateValues: {
    shared: ({ now, t, groups, theme }) => [
      {
        ...presetsColumns.calculateValues.lastUpdateShort({ now, t }),
        ...baseProperties,
      },
      {
        ...presetsColumns.calculateValues.lastUpdate(),
        ...baseProperties,
      },
      presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
      {
        ...presetsColumns.base.groupName,
        ...baseProperties,
        calculateCellValue: (item) => getGroupName(item, groups),
        calculateGroupValue: (item) => getGroupName(item, groups),
      },
      presetsColumns.calculateValues.deviceColor({ theme }),
      presetsColumns.calculateValues.category({ t }),
    ],
  },
  byCategory: {
    smsTemplates: ({ t, availableProtocols }) => [
      {
        name: 'smsTemplateName',
        dataField: 'name',
        caption: 'settingsSmsTemplateName',
        calculateCellValue: (item) => item.name,
      },
      {
        name: 'smsTemplateProtocols',
        dataField: 'protocols',
        caption: 'settingsProtocols',
        calculateCellValue: (item) => (formatProtocols(item.protocols, availableProtocols) ? formatProtocols(item.protocols, availableProtocols) : t('settingsSmsTemplatesAllDevices')),
      },
      {
        name: 'smsTemplateTemplate',
        dataField: 'template',
        caption: 'settingsSmsTemplateText',
        calculateCellValue: (item) => item.template,
      },
      {
        name: 'smsTemplateDescription',
        dataField: 'description',
        caption: 'settingsDescription',
        calculateCellValue: (item) => item.description,
      },

    ],
  },
  common: [
    presetsColumns.common.id,
    presetsColumns.common.name,
  ],
};

import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'sms',
  initialState: {
    items: [],
    newSmsInitialized: false,
    smsChats: [],
    newChatSms: {},
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => !state.items.find((i) => i.id === item.id) && state.items.push(item));
    },
    remove(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    clear(state) {
      state.items = [];
    },
    updateNewSmsInitialized(state, action) {
      state.newSmsInitialized = action.payload;
    },
    addSmsChat(state, action) {
      const deviceId = action.payload;
      if (!state.smsChats.includes(deviceId)) {
        state.smsChats.push(deviceId);
        state.newChatSms[deviceId] = [];
      }
    },

    addSmsChatMsg(state, action) {
      const { deviceId, ...message } = action.payload;
      state.newChatSms[deviceId].push(message);
    },

    removeSmsChatMsg(state, action) {
      const deviceId = action.payload;
      state.newChatSms[deviceId] = [];
    },

    removeSmsChat(state, action) {
      const deviceId = action.payload;
      state.smsChats = state.smsChats.filter((id) => id !== deviceId);
      delete state.newChatSms[deviceId];
    },

    clearSmsChat(state) {
      state.smsChats = [];
      state.newChatSms = {};
    },
  },
});

export { actions as smsActions };
export { reducer as smsReducer };

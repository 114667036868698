import React, { useRef, useState } from 'react';
import {
  TablePagination,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../components/LocalizationProvider';
import TablePaginationActions from '../components/PerfectTable/TablePaginationActions';
import { isUndefinedOrNull } from '../utils/stringUtils';

const EMPTY = 0;

const DEFAULT_ROWS_PER_PAGE = 15;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [15, 30, 50, 100];

const DEFAULT_RADIX = 10;

const DEFAULT_CURRENT_COUNT = -1;

const useSimplePagination = ({
  datesetForPagination = undefined, defaultRowsPerPageOptions = undefined, labelDisplayedRows = undefined,
  ActionsComponent = undefined, labelRowsPerPage = undefined, component = 'div',
  defaultRowsPerPage = undefined,
}) => {
  const t = useTranslation();

  const [page, setPage] = useState(EMPTY);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage ?? DEFAULT_ROWS_PER_PAGE);

  const currentCountRowsPerPage = useRef(DEFAULT_CURRENT_COUNT);

  const handleDisplayedRows = ({ from, to }) => (
    `${from}–${to} ${t('ofRowsLable')} ${currentCountRowsPerPage.current !== DEFAULT_CURRENT_COUNT ? currentCountRowsPerPage.current : `${t('moreThenRowsLable')} ${to}`}`
  );

  const handleChangePage = (event, newPage) => {
    try {
      setPage(newPage);
    } catch (error) {
      console.log('Не удалось изменить номер страницы.', error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    try {
      setRowsPerPage(parseInt(event.target.value, DEFAULT_RADIX));
      setPage(EMPTY);
    } catch (error) {
      console.log('Не удалось изменить количество строк.', error);
    }
  };

  const getProcessedDataset = () => {
    currentCountRowsPerPage.current = datesetForPagination.length;

    // Проверка текущий позиции страницы, в случае отклонения корректирует в меньшую сторону.
    try {
      const pagesSum = Math.ceil(currentCountRowsPerPage.current / rowsPerPage);

      if ((currentCountRowsPerPage.current < rowsPerPage) && page !== EMPTY) {
        setPage(EMPTY);
      } else if (page > pagesSum) {
        const difference = pagesSum - 1;
        setPage(difference > EMPTY ? difference : EMPTY);
      }
    } catch (error) {
      console.log('Произошла ошибка при изменении текущей страницы.', error);
    }
    const processedDataset = datesetForPagination.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return processedDataset;
  };

  const Pagination = (
    <TablePagination
      ActionsComponent={isUndefinedOrNull(ActionsComponent) ? TablePaginationActions : ActionsComponent}
      labelRowsPerPage={isUndefinedOrNull(labelRowsPerPage) ? t('sharedElements') : labelRowsPerPage}
      labelDisplayedRows={isUndefinedOrNull(labelDisplayedRows) ? handleDisplayedRows : labelDisplayedRows}
      rowsPerPageOptions={isUndefinedOrNull(defaultRowsPerPageOptions) ? DEFAULT_ROWS_PER_PAGE_OPTIONS : defaultRowsPerPageOptions}
      component={component}
      count={datesetForPagination.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  return [Pagination, getProcessedDataset];
};

export default useSimplePagination;
